// This file configures the initialization of Sentry on the browser.
// The config you add here will be used whenever a page is visited.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

import * as Sentry from '@sentry/nextjs';

const SENTRY_DSN = process.env.SENTRY_DSN || process.env.NEXT_PUBLIC_SENTRY_DSN;

const environment = process.env.NEXT_PUBLIC_RELEASE_STAGE;

Sentry.init({
  dsn:
    environment === 'staging' || environment === 'production'
      ? SENTRY_DSN || 'https://7aa6ea9d6bb843e1b9f639093d8eddea@o956431.ingest.sentry.io/5905797'
      : null,
  // Adjust this value in production, or use tracesSampler for greater control
  tracesSampleRate: 1.0,
  environment,
  release: process.env.NEXT_PUBLIC_BUILD_ID,
  // ...
  // Note: if you want to override the automatic release value, do not set a
  // `release` value here - use the environment variable `SENTRY_RELEASE`, so
  // that it will also get attached to your source maps
});
