const Navigator = {
  home: '/',
  signIn: '/sign-in',
  signUp: '/sign-up',
  forgotPassword: '/forgot-password',
  setNewPassword: '/setNewPassword',
  business: (slug: string) => `/business/${slug}`,
  style: (slug: string) => `/style/${slug}`,
  directory: (slug: string) => `/${slug}`,
  article: (slug: string) => `/${slug}`,
  fashion: (slug: string) => `/${slug}`,
  dashboard: {
    info: '/dashboard/info',
    videos: '/dashboard/videos',
    uploadVideo: '/dashboard/upload-video',
  },
  // TODO: rest of our file-based routes
};

export default Navigator;
