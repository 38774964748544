import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBadge } from '@fortawesome/pro-solid-svg-icons';
import theme from '~/styles/styledComponentsTheme';
import LSTVLink from '../LSTVLink';
import { useAuthService } from '~/api/hooks/useAuthService';

const Container = styled.div`
  background: ${theme.highlight_2_0};
  height: 25px;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;

  a {
    text-decoration: none;
    :hover {
      text-decoration: underline;
      color: white;
    }
  }
`;

const MessageBar: React.FC = () => {
  const authService = useAuthService();
  if (authService.user?.userType !== 'business_team_member') return null;
  return (
    <Container>
      <FontAwesomeIcon icon={faBadge} />
      &nbsp;
      <LSTVLink to="/dashboard/info">Get the new Love Stories TV badge for your website.</LSTVLink>
    </Container>
  );
};

export default MessageBar;
