import Image from 'next/image';
import { ReactNode } from 'react';
import styled from 'styled-components';
import { LSTVSVG } from '~/components/InlineSVG';
import LSTVButton from '~/components/LSTVButton';
import { HIGHLIGHT_7_0 } from '~/globals';
import loadingGif from '~/images/button-loading-white.gif';

type Size = 'small' | 'medium' | 'large' | 'fullWidth' | 'fullWidthMedium' | 'iconOnly';

interface Props {
  /**
   * Not required for size='iconOnly' but generally a title should be provided.
   */
  title?: string;
  size?: Size;
  icon?: string | ReactNode;
  /**
   * HTML button type: "submit", etc.
   */
  type?: 'button' | 'submit' | 'reset';
  disabled?: boolean;
  loading?: boolean;
  dsm?: boolean;
  onClick?: () => void;
  hideIcon?: boolean;
  iconLeft?: boolean;
  lightMode?: boolean;
  className?: string;
  eventAction?: string;
  style?: any /* TSFIXME */;
}

/**
 * @deprecated in favor of CtaButton. There are two reasons for this: first, we
 * want to be able to style buttons and links in the same way, and this
 * component is tied specifically to a button, and second, there is a new design
 * that's easiest to phase in by switching to another component.
 */
const BaseCTAButton = ({
  title,
  size = 'large',
  disabled,
  loading,
  icon,
  hideIcon,
  style,
  iconLeft,
  onClick,
  lightMode,
  className,
  type,
  dsm,
  eventAction,
}: Props) => {
  return (
    <Button
      type={type}
      $size={size}
      $center={hideIcon}
      disabled={disabled}
      onClick={loading || disabled ? () => null : onClick}
      $iconLeft={iconLeft}
      style={style}
      $lightMode={lightMode}
      className={className}
      $dsm={dsm}
      eventAction={eventAction ?? (onClick !== undefined ? title : undefined)}
    >
      {!hideIcon && iconLeft && (
        <Ripple $size={size} $lightMode={lightMode} className="base-cta__ripple">
          <IconContainer size={size} iconLeft={iconLeft} isCustom={!!icon}>
            {icon ? (
              icon
            ) : (
              <LSTVSVG
                icon="arrow-right"
                fillColor={lightMode ? HIGHLIGHT_7_0 : 'white'}
                strokeColor={lightMode ? HIGHLIGHT_7_0 : 'white'}
              />
            )}
          </IconContainer>
        </Ripple>
      )}
      {title && !loading && (
        <Text {...{ $iconLeft: iconLeft, $hideIcon: hideIcon, $lightMode: lightMode, $dsm: dsm }}>{title}</Text>
      )}
      {loading && (
        <Loading>
          <Image src={loadingGif} width={40} height={40} />
        </Loading>
      )}
      {!hideIcon && !iconLeft && (
        <Ripple {...{ $lightMode: lightMode, $size: size, $dsm: dsm }} className="base-cta__ripple">
          <IconContainer size={size}>
            {icon ?? <LSTVSVG icon="arrow-right" fillColor={lightMode ? HIGHLIGHT_7_0 : 'white'} />}
          </IconContainer>
        </Ripple>
      )}
    </Button>
  );
};

const getSizeStyles = (size, isIconLeft, hideIcon, dsm) => {
  const style = { ...(dsm ? sizeStylesDsm : sizeStyles)[size] };

  if (hideIcon) {
    style.paddingRight = 0;
    style.paddingLeft = 0;
  }

  if (isIconLeft) {
    style.paddingLeft = 0;
    style.paddingRight = 23;
  }

  return style;
};

const sizeStyles = {
  small: {
    width: '100%',
    height: 41,
    paddingLeft: 23,
  },
  medium: {
    width: 185,
    height: 41,
    paddingLeft: 25,
  },
  large: {
    width: 244,
    height: 51,
    paddingLeft: 27,
  },
  fullWidth: {
    width: '100%',
    height: 51,
    paddingLeft: 27,
  },
  fullWidthMedium: {
    width: '100%',
    height: 41,
    paddingLeft: 25,
  },
  iconOnly: {
    width: 'auto',
    height: 51,
    paddingLeft: 0,
  },
};

// For now only tested size=medium, other sizes likely need adjustments.
const sizeStylesDsm = {
  small: {
    width: '100%',
    height: 34,
    paddingLeft: 23,
  },
  medium: {
    width: 182,
    height: 43,
    paddingLeft: 25,
  },
  large: {
    width: 244,
    height: 54,
    paddingLeft: 27,
  },
  fullWidth: {
    width: '100%',
    height: 54,
    paddingLeft: 27,
  },
  fullWidthMedium: {
    width: '100%',
    height: 43,
    paddingLeft: 25,
  },
  iconOnly: {
    width: 'auto',
    height: 54,
    paddingLeft: 0,
  },
};

const largeIconSize = {
  width: '1.25rem',
  height: '1.25rem',
};

const iconSizeStyles = {
  small: {
    width: '1rem',
    height: '1rem',
  },
  medium: {
    width: '1rem',
    height: '1rem',
  },
  large: largeIconSize,
  fullWidth: largeIconSize,
  fullWidthMedium: {
    width: '1rem',
    height: '1rem',
  },
  iconOnly: largeIconSize,
};

const Button = styled(LSTVButton)<{
  $center?: boolean;
  $iconLeft?: boolean;
  $lightMode?: boolean;
  $size: Size;
  $dsm?: boolean;
}>(({ theme, $center, $iconLeft, $lightMode, $size, $dsm }) => ({
  display: 'flex',
  alignItems: 'center',
  textAlign: $center ? 'center' : 'left',
  ...getSizeStyles($size, $iconLeft, $center, $dsm),
  borderRadius: 50,
  background: $lightMode ? 'white' : theme.highlight_7_0,
  transition: 'transform .2s ease-out',
  cursor: 'pointer',
  ':hover': {
    background: $lightMode ? 'white' : theme.highlight_6_0,
    '.base-cta__ripple': {
      backgroundColor: $lightMode ? theme.midGrey : theme.highlight_7_0,
    },
  },
  ':focus': {
    'box-shadow': `0px 0px 6px 0px ${theme.cardDropShadow}`,
  },
  ':disabled': {
    opacity: 0.2,
    cursor: 'not-allowed',
    background: `${$lightMode ? 'white' : theme.highlight_7_0} !important`,
  },
}));

const Text = styled.span<{ $hideIcon?: boolean; $iconLeft?: boolean; $lightMode?: boolean; $dsm?: boolean }>(
  ({ theme, $hideIcon, $iconLeft, $lightMode, $dsm }) => ({
    display: 'block',
    flex: '1',
    width: '100%',
    paddingRight: $hideIcon || $iconLeft ? '0' : '5px',
    paddingLeft: $iconLeft ? '8px' : '0',
    fontWeight: 600,
    color: $lightMode ? theme.highlight_7_0 : 'white',
    ...($dsm ? { fontSize: '21px' } : {}),
  })
);

const Loading = styled.div`
  flex: 1;
  width: 100%;
  display: flex;
  justify-content: center;
`;

export const Ripple = styled.div<{ $size: Size; $lightMode?: boolean; $dsm?: boolean }>(
  ({ theme, $size, $lightMode, $dsm }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: '50%',
    height: ($dsm ? sizeStylesDsm : sizeStyles)[$size].height,
    width: ($dsm ? sizeStylesDsm : sizeStyles)[$size].height,
    backgroundColor: $lightMode ? theme.midGrey : $size === 'iconOnly' ? theme.highlight_7_0 : theme.highlight_6_0,
    transition: 'transform .2s ease-out',
  })
);

const IconContainer = styled.div<{ size: Size; iconLeft?: boolean; isCustom?: boolean }>(
  ({ size, iconLeft, isCustom }) => ({
    height: iconSizeStyles[size].height,
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    transform: !isCustom && iconLeft ? 'rotate(180deg)' : null,
    marginBottom: !isCustom && iconLeft ? '3px' : 0,
  })
);

export default BaseCTAButton;
