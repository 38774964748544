import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { Input } from '~/components/forms/StyledForm';
import { OutlinedCTAButton } from '~/components/OutlinedCTALink';
import { useTrackEvent } from '~/globals/trackEvent';

const InputContainer = styled.div`
  position: relative;
  margin: 10px 0 5px;
`;

const StyledInput = styled(Input)`
  padding: 15px 50px 15px 16px;
  font-family: Calibre;
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 18px;
`;

const CopyButton = styled(OutlinedCTAButton)`
  position: absolute;
  top: 0;
  right: 0;
  font-family: Calibre;
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 18px;
`;

const UrlShareBar = ({ urlToShare }: { urlToShare: string }) => {
  const [url, setUrl] = useState(urlToShare);
  const [urlCopied, setUrlCopied] = useState(false);
  const trackEvent = useTrackEvent();

  useEffect(() => {
    setUrl(urlToShare);
  }, [urlToShare]);

  const onCopy = () => {
    setUrlCopied(true);

    setTimeout(() => {
      setUrlCopied(false);
    }, 3000);

    trackEvent('share', { social_channel: 'link' });
  };
  return (
    <InputContainer>
      <StyledInput value={url} disabled />
      <CopyToClipboard onCopy={onCopy} text={url}>
        <CopyButton className="filled" width="30%" dsm>
          {urlCopied ? 'Copied' : 'Copy'}
        </CopyButton>
      </CopyToClipboard>
    </InputContainer>
  );
};

export default UrlShareBar;
