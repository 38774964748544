import axios from 'axios';
import {
    CONTENT_GRID_CONTENT_TYPE_VIDEO,
    CONTENT_GRID_CONTENT_SEARCH_TYPE_VIBE_TO_EVENT_STORY,
    CONTENT_GRID_CONTENT_SEARCH_TYPE_LOCATION_TO_EVENT_STORY,
    CONTENT_GRID_CONTENT_SORT_METHOD_MOST_RECENT,
} from '~/globals';
import { responseErrorInterceptor } from '../ApiErrorBoundary';
import Uppy from '@uppy/core';
import AwsS3Multipart from '@uppy/aws-s3-multipart';
import { v4 } from 'uuid';

const ccAxiosInstance = axios.create({ baseURL: `${process.env.NEXT_PUBLIC_API_URL}/v1` });
ccAxiosInstance.interceptors.response.use((response) => response, responseErrorInterceptor);
ccAxiosInstance.defaults.withCredentials = true;
const source = axios.CancelToken.source();

const PublicContentService = {
    cancelSource: source,
    async getSlugContent(params) {
        const res = await ccAxiosInstance.get('/slugContent', { params });
        return res.data.result;
    },
    async getVideo(params) {
        const res = await ccAxiosInstance.get('/video', { params });
        return res.data.result;
    },
    async getVideoId(id) {
        const res = await ccAxiosInstance.get(`/video/${id}`, { params: { verbosity: 'admin-full' } });
        return res.data.result;
    },
    async validateBridalCode(code) {
        const res = await ccAxiosInstance.get(`/validateBridalCode`, { params: { code: code } });
        return res.data.result;
    },
    async submitVideoLicensesEdits(payload) {
        const resp = await ccAxiosInstance.post(`/submitMusicLicenses`, payload);
        return resp.data.result;
    },
    async submitBridalEdits(payload) {
        const resp = await ccAxiosInstance.post(`/submitBridalEdits`, payload);
        return resp.data.result;
    },
    async submitBride(payload) {
        const resp = await ccAxiosInstance.post(`/submitBride`, payload);
        return resp.data.result;
    },
    async getVideoBookmarked(slug) {
        const res = await ccAxiosInstance.get(`/video/${slug}/verifyBookmark`);
        return res.data.result.bookmarked;
    },
    async setVideoBookmarked(slug, bookmarked) {
        const path = `/video/${slug}/bookmarkers`;
        const res = await (bookmarked ? ccAxiosInstance.post(path) : ccAxiosInstance.delete(path));
        return res.data.result;
    },
    async getBusinessBookmarked(slug) {
        const res = await ccAxiosInstance.get(`/business/${slug}/verifyBookmark`);
        return res.data.result.bookmarked;
    },
    async setBusinessBookmarked(slug, bookmarked) {
        const path = `/business/${slug}/bookmarkers`;
        const res = await (bookmarked ? ccAxiosInstance.post(path) : ccAxiosInstance.delete(path));
        return res.data.result;
    },
    async contentSearch(params) {
        const res = await ccAxiosInstance.get('/contentSearch', { params });
        return res.data;
    },
    async getContentComposition(params) {
        const res = await ccAxiosInstance.get('/contentComposition', { params });
        return res.data.result;
    },
    async getShoppingItems(elementId, elementType) {
        const res = await ccAxiosInstance.get('/shoppingItem', {
            params: {
                element_type: elementType,
                element_id: elementId,
            },
        });
        return res.data.result;
    },
    async getVibesForCard(slug) {
        try {
            const res = await ccAxiosInstance.get(`/slugContent?slug=/vibe/${slug}`);
            return res?.data?.result;
        } catch (e) {
            throw e.response.data.errors;
        }
    },
    async getVibe(slug) {
        try {
            const res = await ccAxiosInstance.get(`/tag/${slug}`);
            return res?.data?.result;
        } catch (e) {
            throw e.response.data.errors;
        }
    },
    async getVibeVideo(slug) {
        const res = await ccAxiosInstance.get('/contentSearch', {
            params: {
                content_type: CONTENT_GRID_CONTENT_TYPE_VIDEO,
                content_search_type: CONTENT_GRID_CONTENT_SEARCH_TYPE_VIBE_TO_EVENT_STORY,
                search_items: slug,
                content_sort_method: CONTENT_GRID_CONTENT_SORT_METHOD_MOST_RECENT,
                exclude_items: [],
                offset: 0,
                size: 1,
            },
        });
        return res.data.result;
    },
    /**
     * Use along with uploadVideo(). Returns a response with upload_url and temporary_token
     * @param {string} fileName
     */
    async getSignedVideoUploadUrl(fileName) {
        try {
            return ccAxiosInstance.get(`/preAuthorizeUserVideoUpload?filename=${encodeURIComponent(fileName)}`);
        } catch (error) {
            console.error('getSignedUrl error', error);
            throw new Error(error.message);
        }
    },

    /**
     * PUT a video to S3 and enqueue its processing
     * @param {File} file
     * @param {string} filename
     * @param {string} uploadToken
     * @param {Function} handlePercentUpdates
     */
    async uploadVideo(file, filename, uploadToken, handlePercentUpdates) {
        try {
            const uppy = new Uppy({
                // Using random string means Uppy won't be able to properly persist data
                // to localStorage, but doing it the simple way for now.
                id: v4(),
                allowMultipleUploads: false,
                autoProceed: true,
            });

            console.log('NEXT_PUBLIC_UPPY_COMPANION_DOMAIN', process.env.NEXT_PUBLIC_UPPY_COMPANION_DOMAIN);

            uppy.use(AwsS3Multipart, {
                limit: 10,
                companionUrl: process.env.NEXT_PUBLIC_UPPY_COMPANION_DOMAIN ?? 'http://localhost:3020',
            });

            uppy.addFiles([
                {
                    source: 'Local',
                    name: `videos/uploads/${filename}`,
                    type: file.type,
                    data: file,
                },
            ]);

            uppy.on('upload-progress', (file, progress) => {
                // file: { id, name, type, ... }
                // progress: { uploader, bytesUploaded, bytesTotal }
                const precentCompleted = Math.round((progress.bytesUploaded * 100) / progress.bytesTotal);
                handlePercentUpdates(precentCompleted);
            });

            await new Promise((resolve, reject) => {
                uppy.on('complete', () => {
                    resolve();
                });
                uppy.on('error', (error) => {
                    reject(error);
                });
            });

            return ccAxiosInstance.post(`/queueVideoProcessing/${uploadToken}`);
        } catch (error) {
            console.error('uploadVideo error', error);
            throw new Error(error.message);
        }
    },

    async uploadVideoFromUrl(url, name) {
        const payload = { url };
        if (name) {
            payload.name = name;
        }
        const resp = await ccAxiosInstance.post(`/uploadVideoFromURL`, payload);
        return resp.data.result;
    },

    async checkVideoProcessing(videoUploadToken) {
        const resp = await ccAxiosInstance.get(`/checkVideoProcessing/${videoUploadToken}`);
        return resp.data.result;
    },

    async checkVideoFromUrl(videoUploadToken) {
        const resp = await ccAxiosInstance.get(`/checkVideoFromURL/${videoUploadToken}`);
        return resp.data.result;
    },

    /**
     * Upload a file to s3 via a signed URL
     * @param {File} file
     * @returns Download URL for the image
     */
    async uploadPhoto(file) {
        try {
            const res = await ccAxiosInstance.get(`preAuthorizePhotoUpload?filename=${encodeURIComponent(file.name)}`);
            const { upload_url, download_url } = res.data.result;

            await axios({
                method: 'put',
                url: upload_url,
                headers: { 'Content-Type': file.type },
                data: file,
            });

            return download_url;
        } catch (e) {
            console.error('uploadPhoto error', e);
            throw new Error(e.message);
        }
    },
    async getLocation(slug) {
        try {
            const res = await ccAxiosInstance.get(`/location/${slug}`);
            return res?.data?.result;
        } catch (e) {
            throw e.response.data.erros;
        }
    },
    async getLocationVideo(slug) {
        const res = await ccAxiosInstance.get('/contentSearch', {
            params: {
                content_type: CONTENT_GRID_CONTENT_TYPE_VIDEO,
                content_search_type: CONTENT_GRID_CONTENT_SEARCH_TYPE_LOCATION_TO_EVENT_STORY,
                search_items: slug,
                content_sort_method: CONTENT_GRID_CONTENT_SORT_METHOD_MOST_RECENT,
                exclude_items: [],
                offset: 0,
                size: 1,
            },
        });
        return res.data.result;
    },
    async search(term, limit_to_location, verbosity) {
        const res = await ccAxiosInstance.get(`/search`, {
            params: {
                term,
                limit_to_location,
                ...(verbosity !== undefined ? { next_verbosity: verbosity } : {}),
            },
        });
        return res.data.result;
    },
    async selectSearch(term, type) {
        const res = await ccAxiosInstance.get('/search', {
            params: {
                term,
                type,
            },
        });
        return res;
    },

    async postVideo(video, businessSlug) {
        return ccAxiosInstance.post(`/business/${businessSlug}/videos`, video);
    },

    async patchVideo(video, videoId, businessSlug) {
        return ccAxiosInstance.patch(`/business/${businessSlug}/videos/${videoId}`, video);
    },

    /**
     * Returns the 12 directories for the large tile global search menu
     */
    async getSearchDirectories() {
        const res = await ccAxiosInstance.get(`/directory?dropdown=true`);
        return res.data.result;
    },
    /**
     * Returns the ~24 directories used in the biz type search refinement dropdown
     */
    async getSearchRoleDirectories() {
        const res = await ccAxiosInstance.get(`/directory?search_roles=true`);
        return res.data.result;
    },
    async getSearchDirectoriesTimestamp() {
        const res = await ccAxiosInstance.get(`/directory?timestamp`);
        return res.data;
    },
    async getAllSearchDirectories() {
        const res = await ccAxiosInstance.get(`/directory`);
        return res.data;
    },
    async getLocationDirectory(slug, location) {
        const res = await ccAxiosInstance.get(
            `/directory/${slug}?content_category=location&content_category_id=/${location}`
        );
        return res.data;
    },
    async getCompetitions() {
        const res = await ccAxiosInstance.get(`/competitions`);
        return res.data.result;
    },
    async getCompetition(id) {
        const res = await ccAxiosInstance.get(`/competitions/${id}`);
        return res.data.result;
    },
    async getCompetitionSynopsis() {
        const res = await ccAxiosInstance.get(`/competitions?verbosity=card`);
        return res.data.result;
    },

    getActiveCompetition(competitions) {
        const now = new Date().getTime();
        return competitions
            ? [...competitions.present, ...competitions.future].find(
                  ({ timeline: { entries_begin_on, entries_end_on } }) =>
                      now > new Date(entries_begin_on).getTime() && now < new Date(entries_end_on).getTime()
              )
            : undefined;
    },
    getRecentCompetition(competitions) {
        const now = new Date().getTime();
        const eligibleCompetitions = [...competitions.present, ...competitions.future, ...competitions.past]
            .filter(({ timeline: { entries_begin_on } }) => new Date(entries_begin_on).getTime() < now)
            .sort(
                ({ timeline: { entries_begin_on: left } }, { timeline: { entries_begin_on: right } }) =>
                    -1 * left.localeCompare(right)
            );
        return eligibleCompetitions.length > 0 ? eligibleCompetitions[0] : undefined;
    },
    async getVotingHistory(competitionId) {
        const res = await ccAxiosInstance.get(`/competitions/${competitionId}/votes`);
        return res.data.result;
    },
    async getVotingCategories() {
        const res = await ccAxiosInstance.get(`/votingCategories`);
        return res.data.result;
    },
    async getVotingCandidates(category) {
        const res = await ccAxiosInstance.get(`/votingCandidates?category=${category}`);
        return res.data.result;
    },
    async voteForCompetitionEntry(competitionEntryId, relationship, email) {
        try {
            const res = await ccAxiosInstance.post(`/competitionEntries/${competitionEntryId}/votes`, {
                email: email || undefined,
                relationship: relationship || undefined,
            });
            return res.data;
        } catch (e) {
            throw new Error(e?.response?.data?.errors[0]?.errors[0] || 'Check your voting form and try again');
        }
    },
    async getPressItems(offset, size) {
        const res = await ccAxiosInstance.get(`/pressItems?offset=${offset}&size=${size}`);
        return res.data.result;
    },
};

export default PublicContentService;
