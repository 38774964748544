import React, { useState, createContext, useContext } from 'react';

type ShareModalParams = {
  url: string;
  thumbnailUrl?: string;
  title?: string;
};

type FlagModalParams = {
  title?: string;
  onFlag?: (value: any) => void;
  options: { value: string; label: string }[];
};

type ModalsContext = {
  isContactBusinessModalOpen?: boolean;
  isLoginModalOpen?: boolean;
  isFlagModalOpen?: boolean;
  isShareModalOpen?: boolean;

  // TODO: type all the params
  contactBusinessModalParams?: any;
  flagModalParams?: FlagModalParams;
  shareModalParams?: ShareModalParams;

  openContactBusinessModal: (params) => void;
  openLoginModal: () => void;
  openFlagModal: (params: FlagModalParams) => void;
  openShareModal: (params: ShareModalParams) => void;

  closeContactBusinessModal: () => void;
  closeLoginModal: () => void;
  closeFlagModal: () => void;
  closeShareModal: () => void;
};

const modalsContext = createContext<ModalsContext>({
  openContactBusinessModal: null,
  openLoginModal: null,
  openFlagModal: null,
  openShareModal: null,
  closeContactBusinessModal: null,
  closeLoginModal: null,
  closeFlagModal: null,
  closeShareModal: null,
});

const useModals = () => {
  return useContext(modalsContext);
};

const useProvideModals = () => {
  const [isContactBusinessModalOpen, setIsContactBusinessModalOpen] = useState(false);
  const [isLoginModalOpen, setIsLoginModalOpen] = useState(false);
  const [isFlagModalOpen, setIsFlagModalOpen] = useState(false);
  const [isShareModalOpen, setIsShareModalOpen] = useState(false);

  const [contactBusinessModalParams, setContactBusinessModalParams] = useState(null);
  const [flagModalParams, setFlagModalParams] = useState(null);
  const [shareModalParams, setShareModalParams] = useState(null);

  const openContactBusinessModal = (args) => {
    setIsContactBusinessModalOpen(true);
    setContactBusinessModalParams(args);
  };

  const openLoginModal = () => {
    setIsLoginModalOpen(true);
  };

  const openFlagModal = (args) => {
    setIsFlagModalOpen(true);
    setFlagModalParams(args);
  };

  const openShareModal = (args) => {
    setIsShareModalOpen(true);
    setShareModalParams(args);
  };

  const closeContactBusinessModal = () => {
    setIsContactBusinessModalOpen(false);
    setContactBusinessModalParams(null);
  };

  const closeLoginModal = () => setIsLoginModalOpen(false);
  const closeFlagModal = () => setIsFlagModalOpen(false);
  const closeShareModal = () => setIsShareModalOpen(false);

  return {
    isContactBusinessModalOpen,
    isLoginModalOpen,
    isFlagModalOpen,
    isShareModalOpen,

    contactBusinessModalParams,
    flagModalParams,
    shareModalParams,

    openContactBusinessModal,
    openLoginModal,
    openFlagModal,
    openShareModal,

    closeContactBusinessModal,
    closeLoginModal,
    closeFlagModal,
    closeShareModal,
  };
};

function ModalsProvider({ children }) {
  const modals = useProvideModals();
  return <modalsContext.Provider value={modals}>{children}</modalsContext.Provider>;
}

export { useModals, ModalsProvider };
