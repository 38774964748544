import React, { useRef, useEffect, useState, createContext, useContext } from 'react';
import debounce from 'lodash.debounce';
import PublicContentService from '~/api/services/publicContentService';
import { convertGoogleLocation } from '~/utils/LSTVUtils';
import useQueryParams from '~/hooks/useQueryParams';
import { useRouter } from 'next/router';
import { useTrackEvent } from '~/globals/trackEvent';

// TODO: obviously needs somee work as we're not using react-router now

const RESULTS_PAGE_PATHNAME = '/results';

export enum searchSources {
  header = 'header',
  page = 'page',
  home = 'homepage',
  modal = 'modal',
}

const LOCATION = 'location';
const FREETEXT = 'freetext';
const LOCATION_RESULTS = 'location-results';
const FREETEXT_RESULTS = 'freetext-results';
const NO_INPUT = 'no-input';
const NO_RESULTS = 'no-results';
// these refer to the new simpler free-text search input in the header (except on the homepage)
const SIMPLE_SEARCH = 'simplesearch';
const SIMPLE_SEARCH_RESULTS = 'simplesearch-results';

const resultsTypes = {
  LOCATION_RESULTS,
  FREETEXT_RESULTS,
  SIMPLE_SEARCH_RESULTS,
  NO_INPUT,
  NO_RESULTS,
};

export const fields = {
  LOCATION,
  FREETEXT,
  SIMPLE_SEARCH,
};

const SearchContext = createContext({
  isSearchModalOpen: false,
  shouldRenderSearchPanel: true,
  isLoading: false,
  isResultsOpen: false,
  shouldFocusLocation: false,
  results: null,
  currentSearchSource: '',
  currentFocusedField: null,
  locationQuery: '',
  selectedLocation: null,
  selectedDirectory: null,
  query: '',
  fields,
  resultsTypes,
  openSearchModal: () => {},
  closeSearchModal: () => {},
  handleFieldFocus: (source, field) => {},
  handleSelectLocation: (location) => {},
  handleClickOutside: () => {},
  handleSelectFreeText: (item) => {},
  handleSearchButtonClick: () => {},
  hideSearchPanel: () => {},
  showSearchPanel: () => {},
  handleSearch: (field, value) => {},
  closeResults: () => {},
});

const useSearch = () => {
  return useContext(SearchContext);
};

const useProvideSearch = () => {
  const [, urlLocation, setParams] = useQueryParams();
  const getAddressPredictions = useAddressPredictions();
  // const history = useHistory();
  // const location = useLocation();
  const router = useRouter();

  const [results, setResults] = useState({
    type: resultsTypes.NO_INPUT,
    data: [],
  });
  const [isResultsOpen, setIsResultsOpen] = useState(false);
  const [isSearchModalOpen, setIsSearchModalOpen] = useState(false);
  const [shouldRenderSearchPanel, setShouldRenderSearchPanel] = useState(true);
  /* TSFIXME */
  const [selectedLocation, setSelectedLocation] = useState<any>();
  const [selectedDirectory, setSelectedDirectory] = useState(null);
  const [currentFocusedField, setCurrentFocusedField] = useState(null);
  const [currentSearchSource, setCurrentSearchSource] = useState('');
  const [shouldFocusLocation, setShouldFocusLocation] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [query, setQuery] = useState('');
  const [locationQuery, setLocationQuery] = useState('');

  useEffect(() => {
    if (selectedDirectory || query) {
      handleSearchButtonClick();
    }
  }, [selectedLocation]);

  useEffect(() => {
    if (location.pathname !== RESULTS_PAGE_PATHNAME) {
      setQuery('');
      setSelectedDirectory(null);
      // setSelectedLocation(null);
    }

    if (isResultsOpen) {
      setIsResultsOpen(false);
    }

    setResults({
      type: NO_INPUT,
      data: [],
    });
    setCurrentFocusedField(null);
    setCurrentSearchSource('');
  }, [router.pathname]);

  const trackEvent = useTrackEvent();

  const handleClickOutside = () => {
    // want to preserve results upon blurring simple search
    if (currentFocusedField !== fields.SIMPLE_SEARCH) {
      setQuery('');
      setResults({
        type: NO_INPUT,
        data: [],
      });
      setCurrentFocusedField(null);
    }

    setCurrentSearchSource('');
    setIsResultsOpen(false);
  };

  const handleFieldFocus = (source, field) => {
    if (!Object.values(fields).includes(field)) {
      throw new Error('[use-search.handleFieldFocus] Field Not Found');
    }

    setCurrentSearchSource(source);
    setCurrentFocusedField(field);

    if (field === fields.LOCATION) {
      setResults({
        type: resultsTypes.LOCATION_RESULTS,
        data: [],
      });
      setIsResultsOpen(false);
    } else if (field === fields.FREETEXT) {
      setResults({
        type: resultsTypes.NO_INPUT,
        data: [],
      });
      setIsResultsOpen(true);
    } else if (field === fields.SIMPLE_SEARCH) {
      setIsResultsOpen(true);
    }
  };

  const searchFreeText = async (value) => {
    if (query !== value) {
      setQuery(value);
    }

    setIsLoading(true);
    let locationUrl;

    if (selectedLocation) {
      locationUrl = convertGoogleLocation(selectedLocation);
    }
    const request = await PublicContentService.search(value, locationUrl || '');

    if (!request.found?.directories && !request?.found?.businesses) {
      setIsLoading(false);
      return setResults({
        type: resultsTypes.NO_RESULTS,
        data: [],
      });
    }

    const data = [...request.directories, ...request.businesses];

    setResults({
      type: resultsTypes.FREETEXT_RESULTS,
      data: data,
    });
    setIsLoading(false);
  };

  const handleSearch = async (field, value) => {
    if (!value && field !== fields.LOCATION) {
      setQuery(value);
      return setResults({
        type: resultsTypes.NO_INPUT,
        data: [],
      });
    }

    if (field === fields.FREETEXT || field === fields.SIMPLE_SEARCH) {
      setQuery(value);
      await searchFreeText(value);
    } else if (field === fields.LOCATION) {
      if (!value) {
        setSelectedLocation(null);
        setLocationQuery('');
        return;
      }

      setLocationQuery(query);
      const data = await getAddressPredictions(value);

      if (!isResultsOpen) {
        setIsResultsOpen(true);
      }

      return setResults({
        type: resultsTypes.LOCATION_RESULTS,
        // @ts-ignore
        data,
      });
    }
  };

  const handleSelectLocation = (location) => {
    if (!location) {
      setSelectedLocation(null);
      return;
    }

    if (!window) return;

    const div = document.createElement('div');
    const places = new google.maps.places.PlacesService(div);
    places.getDetails(
      {
        placeId: location.place_id,
        fields: ['address_components', 'geometry.location', 'place_id', 'formatted_address'],
      },
      (place, status) => {
        setResults({
          type: resultsTypes.NO_INPUT,
          data: [],
        });
        setIsResultsOpen(false);

        if (status === 'OK') {
          setSelectedLocation(place);
        } else {
          setSelectedLocation(location);
        }
      }
    );
  };

  const handleSelectFreeText = (item) => {
    if (item?.content_type === 'business') {
      setQuery(item.name);
      setSelectedDirectory(item);
      setShouldFocusLocation(true);
      setIsResultsOpen(false);
      setParams(item.slug, selectedLocation ? convertGoogleLocation(selectedLocation) : urlLocation);
      return;
    }

    if (item?.type === 'business') {
      router.push(`/business/${item?.slug}`);
      setIsSearchModalOpen(false);
    }

    closeAll();
  };

  const handleSearchButtonClick = async () => {
    let path = `/results?q=`;
    let analytics_query, analytics_location;

    if (selectedDirectory && query !== selectedDirectory?.name) {
      setSelectedDirectory(null);
      path += `${query}`;
      analytics_query = query;
    } else if (selectedDirectory?.name) {
      path += `${selectedDirectory?.slug || ''}`;
      analytics_query = selectedDirectory?.slug || '';
    } else {
      path += `${query}`;
      analytics_query = query;
    }

    if (selectedLocation?.place_id) {
      const pathLocation = convertGoogleLocation(selectedLocation);
      path += `&loc=${pathLocation}`;
      analytics_location = pathLocation;
    } else {
      setSelectedLocation(null);
    }

    trackEvent('search', {
      query: analytics_query,
      ...(analytics_location !== undefined ? { location: analytics_location } : {}),
      content_type: 'business',
    });

    router.push(path);
    closeAll();
  };

  const closeAll = () => {
    setIsSearchModalOpen(false);
    setIsResultsOpen(false);
  };

  return {
    fields,
    resultsTypes,
    currentFocusedField,
    currentSearchSource,
    isSearchModalOpen,
    isResultsOpen,
    isLoading,
    shouldRenderSearchPanel,
    shouldFocusLocation,
    results,
    query,
    selectedLocation,
    selectedDirectory,
    handleClickOutside,
    handleFieldFocus,
    handleSelectLocation,
    handleSelectFreeText,
    handleSearchButtonClick,
    locationQuery,
    handleSearch: debounce(handleSearch, 650),
    openSearchModal: () => setIsSearchModalOpen(true),
    closeSearchModal: () => setIsSearchModalOpen(false),
    hideSearchPanel: () => setShouldRenderSearchPanel(false),
    showSearchPanel: () => setShouldRenderSearchPanel(true),
    openResults: () => setIsResultsOpen(true),
    closeResults: () => setIsResultsOpen(false),
  };
};

const useAddressPredictions = () => {
  const autocomplete = useRef();

  if (!autocomplete.current && typeof window !== 'undefined' && window.google?.maps) {
    autocomplete.current = new window.google.maps.places.AutocompleteService(null, {
      fields: ['address_components', 'geometry.location', 'place_id', 'formatted_address'],
    });
  }

  function getPlacePredictions(input) {
    return new Promise((resolve) => {
      // @ts-ignore
      autocomplete.current.getPlacePredictions(
        {
          input,
          types: ['(regions)'],
        },
        (predictions) => {
          resolve(predictions);
        }
      );
    });
  }

  return getPlacePredictions;
};

function SearchProvider({ children }) {
  const search = useProvideSearch();
  return <SearchContext.Provider value={search}>{children}</SearchContext.Provider>;
}

export { useSearch, useAddressPredictions, SearchProvider };
