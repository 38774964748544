import { useEffect } from 'react';
import mixpanel from 'mixpanel-browser';

/**
 * Initializes and identifies user to Mixpanel, etc.
 */
const Analytics = () => {
  useEffect(() => {
    mixpanel.init('35d9e21c117f16c07761183920e4d598');
  }, []);

  return null;
};

export default Analytics;
