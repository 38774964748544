import axios from 'axios';
import * as Yup from 'yup';

import { popMessageError, popMessageSuccess } from '~/utils/LSTVUtils';

const campaignServiceAxios = axios.create({ baseURL: `${process.env.NEXT_PUBLIC_API_URL}/v1` });
campaignServiceAxios.defaults.withCredentials = true;

const emailSchema = Yup.string().email('The email is in invalid format.').required('Please enter an email.');

const CampaignService = {
  async subscribeWithPop(email: string, klaviyo_list_id: string): Promise<boolean> {
    const error = await CampaignService.validateAndAddToList(email, 'TyP6hy');
    if (error) {
      popMessageError(error);
      return false;
    }
    popMessageSuccess('Thank you for subscribing!');
    return true;
  },

  async validateAndAddToList(email: string, klaviyo_list_id: string): Promise<string | null> {
    try {
      emailSchema.validateSync(email);
      try {
        await CampaignService.postEmailEvent({ email, slug: klaviyo_list_id || 'VM7QpN' });
        return null;
      } catch {
        return 'Network error, please try again later';
      }
    } catch (error) {
      if (error instanceof Yup.ValidationError) {
        return error.errors[0];
      } else {
        return String(error);
      }
    }
  },

  async postEmailEvent(data: { email: string; slug: string }) {
    const res = await campaignServiceAxios.post('/campaignEmail', data);
    return res.data.result;
  },
};

export default CampaignService;
