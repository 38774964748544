import { createMedia } from '@artsy/fresnel';

// these match values in globals/breakpoints
// eventually we want to stop using that file and theme.breakpoints
export const breakpoints = {
  xs: 0,
  /**
   * Used as lessThan='sm' this is equivalent to the old deprecated
   * const [isMobile] = useMediaReady(breakpoints.isMobile)
   *
   * Used as greaterThan='sm would be like !isMobile above
   */
  sm: 768,
  /**
   * Use as above - equiv to isMobileOrTablet
   */
  md: 1025,
  lg: 1440,
};

/**
 * Helper for use in `@media` queries. Queries width >= breakpoint.
 */
export const minWidth = (breakpoint: number | keyof typeof breakpoints) =>
  `(min-width: ${typeof breakpoint === 'number' ? breakpoint : breakpoints[breakpoint]}px)`;

/**
 * Helper for use in `@media` queries. Queries width < breakpoint.
 */
export const maxWidth = (breakpoint: number | keyof typeof breakpoints) =>
  `(max-width: ${(typeof breakpoint === 'number' ? breakpoint : breakpoints[breakpoint]) - 0.02}px)`;

const FresnelMedia = createMedia({ breakpoints });

// Make styles for injection into the header of the page
export const mediaStyles = FresnelMedia.createMediaStyle();
export const { Media, MediaContextProvider } = FresnelMedia;
