import { useState } from 'react';

export interface IServerError {
  success: false;
  response_code: null;
  response_errors: Record<string, string[]> | { generic?: string[] };
  request_errors: { generic?: string[] };
}

export const useServerErrors = () => {
  const [errorMessages, setErrorMessages] = useState(undefined);

  return {
    analyzeServerErrors(errors: any): IServerError {
      let rc: IServerError = {
        success: false,
        response_code: null,
        response_errors: {},
        request_errors: {},
      };

      if (Array.isArray(errors?.response?.data?.errors)) {
        rc.response_code = errors?.response?.status;
        errors.response.data.errors.forEach((d) => {
          if (d.field) {
            if (rc.response_errors[d.field]) {
              rc.response_errors[d.field].push(` ${d.errors.join('. ')}`);
            } else {
              rc.response_errors[d.field] = [d.errors.join('. ')];
            }
          } else {
            if (rc.response_errors?.generic) {
              rc.response_errors.generic.push(` ${d.errors.join('. ')}`);
            } else {
              rc.response_errors.generic = [d.errors.join('. ')];
            }
          }
        });
      } else {
        if (errors?.response) {
          rc.response_code = errors.response?.status;
          rc.response_errors.generic = [errors.response?.statusText];
        } else {
          rc.request_errors.generic = ['Server unreachable. Check your connection and try again'];
        }
      }

      setErrorMessages(rc);
      return rc;
    },
    errorMessages,
  };
};
