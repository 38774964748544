import { useRef } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/pro-regular-svg-icons';
import { useOnClickOutside } from '~/hooks/useOnClickOutside';
import { useSearch } from '~/hooks/useSearch';
import SearchResults from '../SearchResults';
import * as S from './index.styles';

const SimpleSearch = ({ source }: { source: string }) => {
  const { handleSearch, handleFieldFocus, handleClickOutside, fields, isResultsOpen } = useSearch();

  const containerRef = useRef();
  const resultsRef = useRef();

  useOnClickOutside(containerRef, resultsRef, () => {
    handleClickOutside();
  });

  const onFocus = () => {
    handleFieldFocus(source, fields.SIMPLE_SEARCH);
  };

  const onChange = (e) => {
    handleSearch(fields.SIMPLE_SEARCH, e.target.value);
  };

  return (
    <S.Container ref={containerRef}>
      <S.InputContainer>
        <S.SearchInput placeholder="Search" onChange={onChange} onFocus={onFocus} />
        <S.IconContainer>
          <FontAwesomeIcon color="#323233" icon={faSearch} />
        </S.IconContainer>
      </S.InputContainer>
      <div ref={resultsRef}>{isResultsOpen && <SearchResults />}</div>
    </S.Container>
  );
};

export default SimpleSearch;
