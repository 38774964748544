import React, { useContext } from 'react';
import styled from 'styled-components';

const RadioButtonGroupContext = React.createContext({ name: 'name', layout: 'column', dsm: false });

export const RadioButton = (props) => {
  const { name, dsm } = useContext(RadioButtonGroupContext);
  return (
    // We duplicate `disabled` attribute here because this is the only way
    // to style the element depending on whether the radio button is enabled.
    <RadioButtonWrapper className="radio__wrapper" $primary={props.primary} $disabled={props.disabled} $dsm={dsm}>
      <label className="radio">
        <span className="radio__input">
          <input
            type="radio"
            name={name}
            checked={props.groupValue === props.value}
            value={props.value}
            onChange={() => props.handleChange(props.value)}
            disabled={props.disabled}
          />
          <span className="radio__control"></span>
        </span>
        <div className="radio__desc">
          <span className="radio__label" style={props.labelStyle}>
            {props.children ?? props.labelName}
          </span>
          {props.labelDesc && <span className="radio__label__desc">{props.labelDesc}</span>}
        </div>
      </label>
    </RadioButtonWrapper>
  );
};

const RadioButtonGroupWrapper = styled.div(
  (props) => `
  display: flex;
  flex-direction: ${props.$layout ?? 'column'};

  .radio__wrapper + .radio__wrapper {
    ${props.$layout === 'row' ? 'margin-left: 42px' : 'margin-top: 30px'};
  }
`
);

/**
 * The `name` prop should be unique to the radio group and will be used for each input element.
 *
 * `layout` is either `'column'` (default) or `'row'`.
 */
export const RadioButtonGroup = ({ name, layout = 'column', dsm = false, children = undefined }) => (
  <RadioButtonGroupContext.Provider value={{ name, layout, dsm }}>
    {dsm ? <RadioButtonGroupWrapper $layout={layout}>{children}</RadioButtonGroupWrapper> : children}
  </RadioButtonGroupContext.Provider>
);

const RadioButtonWrapper = styled.div((props) =>
  props.$dsm
    ? `
  flex: 1 1 0;

  .radio {
    display: flex;
    align-items: baseline;
  }

  &:not([disabled]) .radio {
    cursor: pointer;
  }

  .radio + .radio {
    padding-top: 14px;
  }

  .radio__input {
    position: relative;
    width: 44px;
    height: 0;
  }

  .radio__control {
    border-radius: 100%;
    position: absolute;
    left: 11px;
    top: -18px;
    box-sizing: border-box;
    width: 22px;
    height: 22px;
    border: 1px solid ${props.theme.darkerGrey};
    background-color: ${props.theme.white};
  }

  &:hover .radio__input input:not([disabled]) {
     & + .radio__control {
      border: 1px solid ${props.theme.highlight_7_0};
    }

    &:checked + .radio__control {
      background-color: transparent;
      border: none;
      box-shadow: 0 0 0 1px ${props.theme.lightHighlightColor};

      &::before {
        content: "";
        position: absolute;
        top: 1px;
        left: 1px;
        box-sizing: border-box;
        width: 20px;
        height: 20px;
        border-radius: 100%;
        border: 5px solid ${props.theme.highlight_7_0};
        background-color: ${props.theme.white};
      }
    }
  }

  .radio__input input {
    position: absolute;
    opacity: 0;
    width: 1px;
    height: 1px;

    &:checked + .radio__control {
      border: 6px solid ${props.theme.highlight_7_0};
    }

    &:disabled + .radio__control {
      opacity: 0.2;
    }
  }

  .radio__desc {
    display: flex;
    flex-direction: column;
    margin-left: 10px;
  }

  &[disabled] .radio__desc {
    opacity: 20%;
  }

  .radio__label {
    font-weight: 600;
    font-size: 1.312rem;
    line-height: 1.562rem;
    color: ${props.theme.black};
  }

  .radio__label__desc {
    font-size: 1.125rem;
    line-height: 1.5rem;
    color: ${props.theme.darkerGrey};
  }
`
    : `
  cursor: pointer;

  .radio {
    font-size: 2.25rem;
    color: ${props.$primary ? props.theme.highlight_7_0 : props.theme.black};
    display: grid;
    grid-template-columns: min-content auto;
    grid-gap: 0.5em;
    align-items: center;
    padding: 10px;
    opacity: ${props.$disabled ? 0.5 : 1};
  }

  .radio__input {
    display: flex;
    margin-top: 4px;

    input {
      opacity: 0;
      width: 0;
      height: 0;
      position: relative;

      &:checked + .radio__control {
        &:after {
          content: '';
          display: block;
          position: absolute;
          top: calc(50% - 5px);
          left: calc(50% - 5px);
          width: 10px;
          height: 10px;
          border-radius: 50%;
          background: currentColor;
        }
      }
    }
  }

  .radio__control {
    display: block;
    width: 22px;
    height: 22px;
    border-radius: 50%;
    border: 0.2em solid currentColor;
    transform: translateY(-0.05em);
  }

  .radio__desc {
    display: flex;
    flex-direction: column;
  }

  .radio__label {
    font-weight: 600;
    font-size: 1.312rem;
    line-height: 1.562rem;
    color: ${props.theme.black};
  }

  .radio__label__desc {
    font-size: 1.125rem;
    line-height: 1.5rem;
    color: ${props.theme.darkerGrey};
  }
`
);
