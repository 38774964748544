import { useState } from 'react';
import useUserEventService from '../hooks/useUserEventsService';
import axios from 'axios';
import { useServerErrors } from '../hooks/useServerErrors';

const userEventServiceAxios = axios.create({ baseURL: `${process.env.NEXT_PUBLIC_API_URL}/v1` });
userEventServiceAxios.defaults.withCredentials = true;

const source = axios.CancelToken.source();

export const userEventsService = () => {
  return {
    cancel() {
      // UserEventsService.source.cancel("Component got unmounted");
    },
    async getUser() {
      const res = await userEventServiceAxios.get('/user');
      return res.data.result;
    },
    async getUserProperties() {
      const res = await userEventServiceAxios.get('/userProperties', {
        cancelToken: source.token,
        params: { domain: 'profile' },
      });
      return res.data.result;
    },
    async reportBufferedUserEvents(data) {
      const res = await userEventServiceAxios.post('/userBufferedEvents', {
        events: data,
      });
      return res.data.result;
    },
    async postUserEvent(data) {
      const res = await userEventServiceAxios.post('/userEvent', data);
      return res.data.result;
    },
  };
};
