import styled from 'styled-components';

export const DATE_FORMAT = 'yyyy-MM-dd';

/* ---------------- New Design by OLIVIER ------------------------------------------------------------------------*/

/* COLORS AND BACKGROUND */

export const LSTV_YELLOW = '#fff072';
export const PRIMARY_COLOR = '#B86747';
export const PRIMARY_HIGHLIGHT_COLOR = '#9980FD';
export const SECONDARY_COLOR = '#E3A887';
export const UI_ELEMENT_OFF_COLOR_HIGHLIGHT = '#E7E7E7';
export const UI_ELEMENT_OFF_COLOR = '#C9C4C4';
export const MID_GRAY_COLOR = '#ececec';
export const DARK_GRAY_COLOR = '#C9C4C4';
export const LIGHT_GRAY_COLOR = '#F9F9F9';
export const TEXT_AND_SVG_BLACK = '#323233';
export const TEXT_ERROR = '#FF9D9D';
export const TEXT_LIGHT_GREY = '#909090';
export const PAGE_CONTENT_BG_COLOR = '#ffffff';
export const CARD_BACKGROUND = '#ffffff';
export const CARD_LABEL_COLOR_VENDOR = '#FEE504';
export const CARD_LABEL_COLOR_VIBE = '#EFB4B0';
export const CARD_LABEL_COLOR_LOCATION = '#D0F4D0';

/* FONT WEIGHTS */

export const FONT_WEIGHT_LIGHT = 200;
export const FONT_WEIGHT_NORMAL = 400;
export const FONT_WEIGHT_MEDIUM = 500;
export const FONT_WEIGHT_SEMIBOLD = 600;
export const FONT_WEIGHT_BOLD = 700;
export const FONT_WEIGHT_BLACK = 900;

/* ---------------- OLDER DESIGN BY RONEN -- DELETE GRADUALLY AS CONSTS AREN'T USED ANY LONGER -----------------*/

/* COLORS */

//export const MAIN_CONTENT_BG_COLOR = '#f1f1ea';
export const MAIN_CONTENT_BG_COLOR = '#ffffff';

//export const CONTENT_RIGHT_BG_COLOR = "linear-gradient(to bottom, #fbfbf8, 90%, #f1f1ea)";
//export const CONTENT_LEFT_BG_COLOR = "linear-gradient(to bottom, #ffffff, 90%, #f1f1ea)";
//export const CARD_SECTIONS_BG_COLOR = "linear-gradient(to bottom, #ffffff, 100px, #f1f1ea)";

export const CONTENT_RIGHT_BG_COLOR = '#fff';
export const CONTENT_LEFT_BG_COLOR = '#fff';
export const CARD_SECTIONS_BG_COLOR = '#fff';

export const HOLE_BG_COLOR = '#ffffff';

// New colours (also defined in styledComponentsTheme.js)
export const HIGHLIGHT_7_0 = '#B86747';
export const HIGHLIGHT_6_0 = '#E3A887';
export const HIGHLIGHT_COLOR = '#9980fd';
export const LIGHTGREY = '#f9f9f9';
export const MIDGREY = '#ececec';
export const DARKGREY = '#c9c4c4';
export const DARK_NIGHT_BLACK = '#323233';

//export const RIGHT_CONTENT_TAG_BG = "#EDE6E3";
export const TAG_BG = '#eaeaea';
export const VENDOR_TAG_ICON_BG = '#dadada';

export const DEFAULT_PILL_BG_COLOR = '#e0e0e07a';
export const RIGHT_CONTENT_TAG_TEXT_COLOR = '#333';

export const LSTV_GREEN = '#57a22e';
export const LSTV_YELLOW_DARKER = '#ebdc71';
export const LSTV_ERROR_PAGE_500_COLOR = '#3f0d12';
export const LSTV_ERROR_PAGE_INTERNAL = 'linear-gradient(to bottom,#f9adb4,#fd5c5c)';
export const LSTV_ERROR_PAGE_500_GRADIENT = 'linear-gradient(to right, #200122, #6f0000)';
export const DEFAULT_BUTTON_HOVER_BG_COLOR = '#fff032';
export const BUTTON_DOWN_COLOR_PURPLE = '#ff59ab';
export const SIDEBAR_BACKGROUND_COLOR = '#f7f7f5';
export const BOAST_BAR_GRADIENT_COLOR_1 = 'rgba(243,243,245,0.9)';
export const BOAST_BAR_GRADIENT_COLOR_2 = 'rgba(240,240,242,0.9)';

export const CARD_LOADING_GRADIENT_COLOR_1 = '#fdfbfb';
export const CARD_LOADING_GRADIENT_COLOR_2 = '#ebedee';

export const VIDEO_POST_LC_VENDORS_BG = '#e9e9e961';
export const SHOWCASE_SECTIONS_BG_COLOR = '#ffffff';
export const CARD_LOADING_GRADIENT_COLOR_4 = '#f4f4f2';
export const LIGHT_NEAR_WHITE_ELEMENT_BG = '#f4f4f2';
export const CARD_LOADING_SPINNER_COLOR = '#cbcbc9';
export const MAIN_VIDEO_MODULE_BORDER_COLOR = '#e3e3e1';
export const ACCENT_COLOR_DARKER = '#b52565';

export const FORM_INSTRUCTION_COLOR = '#5f5c5c';
export const ACCENT_COLOR_2 = '#E74C3C';
export const BLACK = '#111';

export const FOOTER_FOREGROUND_COLOR = '#e0e0e0';

export const SECONDARY_CARD_TEXT_COLOR = '#adadad';
export const SECONDARY_CARD_TEXT_COLOR_DARKER = '#919191';

export const MODAL_HEADER_BG = '#3b3939';
export const MODAL_HEADER_TITLE_COLOR = '#ffffff';
export const EMBED_BORDER_COLOR = '#c5c5c5';
export const TEXT_AND_SVG_BLACK_LIGHTER = '#999999';
export const TEXT_AND_SVG_LIGHTER_BLACK = '#4b4748';
export const TEXT_AND_MILD_GRAY_BORDER_FOR_TEXT_CONTROLS = '#e2dcde';
export const TAG_HOLE_SHADOW = '#aba7a7';
export const WHITE = '#EEEEEE';
export const OFFWHITE = '#ffffff';
export const ABSOLUTE_WHITE = '#FFFFFF';
export const HEADERBAR_BG_COLOR = '#FFFFFF';
export const DEFAULT_MODAL_BACKGROUND = '#ffffff';
export const DEFAULT_MODAL_BACKGROUND_HEADER = 'linear-gradient(to bottom, #fff072, 96%, #f1d905)';
export const DEFAULT_FORM_FIELD_BG = '#f7f3f3';
export const SWITCH_BG_COLOR = '#eae4e4';
export const HEADERBAR_BG_COLOR_FIXED = '#f1f1f1';
export const HEADERBAR_BG_COLOR_FIXED_BLUR = '#F9F9F9';
export const SIDEBAR_SECTION_SEPARATOR_COLOR_FOR_BLUR = '#33333324';
export const VIDEO_SECTIONS_BACKGROUND = '#FFFFFF';
export const SIDE_AND_NAV_BAR_BG = '#FFFFFF';
export const DISABLED_COLOR = '#928E97';
export const DISABLED_COLOR_BUTTONS = '#d7d7d7';
export const DISABLED_BUTTON_BG = '#626262';
export const DISABLED_BUTTON_TEXT_COLOR = '#c1bebe';
export const CARD_DISABLED_COLOR = '#e0e0de';
export const DISABLED_DARKER = '#7b7780';
export const OVERLAY_COLOR = 'rgba(6,6,6,0.80)';
export const NAVBAR_DROPDOWN_BORDER = '#eee';
export const SIDEBAR_SUBITEM_SEPARATOR_COLOR = '#f7f7f5';
export const HOME_PAGE_FEATURE_BOAST_SECTION_BG = '#e0e0de';
export const DEFAULT_PILL_BORDER_COLOR = 'rgba(88,82,82,0.07)';
export const BLOG_STORY_CARD_DATE_BADGE_BG_COLOR = 'linear-gradient(to right,#eeeeee,#dddddd)';
export const BLOG_STORY_CARD_DATE_BADGE_MONTH_BG_COLOR = 'linear-gradient(to right, #000000,#3c3b40)';
export const WHITE_DOWN_TO_GRAY_BG = 'linear-gradient(to bottom, #ffffff, #F7F5F6)';
//export const CARD_DETAILS_SECTION_BG_COLOR = "#f9f7f7";
export const CARD_DETAILS_SECTION_BG_COLOR = '#ffffff';
export const CARD_PROTRUDING_DETAILS_BORDER_COLOR = '#e6e6e6';
export const CALENDAR_DAY_BADGE_MONTH_BG = '#7E252F';
export const HEART_RED = '#e91409';
export const HEART_RED_2 = '#ff0f09';
export const VENDOR_TILE_ICON_BG = '#e3cd1c';
export const VENDOR_TILE_ICON_BORDER = '#A59893';

export const CARD_BACKGROUND_DARKER = '#E7E9EB';
export const CARD_BACKGROUND_DARKEST = '#D1D3D5';
export const CARD_BORDER_COLOR = 'rgba(227,227,225,0.35)';

export const CARD_TAGS_BORDER = '#d8dbde';
export const AWARD_BADGE_OUTLINE = 'rgba(41,20,7,0)';
export const ACTION_BAR_SEPARATOR_COLOR = '#726e6e';
export const CARD_DROP_SHADOW_COLOR = '#bababa';
export const CARD_DROP_SHADOW_COLOR_DARKER = '#ababab';
export const CARD_MOBILE_ACTION_BAR_ELEMENT_COLOR = '#969696';
export const INLINE_LABEL_AND_CONTENT = '#EDE6E2';
export const DISABLED_FRAME_TEXT = '#d8d8df';
export const DARK_GRAY_FOR_ICONS_BRANDS_LOGOS = '#707070';
export const DATE_WIDGET_MONTH_BG_COLOR = 'linear-gradient(to right,#a32929,#ff6666 75%)';

/* POPUP MESSAGES */

export const POPUP_MESSAGE_TYPE_INFO = 'info';
export const POPUP_MESSAGE_TYPE_SUCCESS = 'success';
export const POPUP_MESSAGE_TYPE_ERROR = 'error';

export const POPUP_MESSAGE_POSITION_TOP_RIGHT = 'top-right';

/* LIMITS */

export const MAX_VIBES_IN_EVENT_STORY_DISPLAY = 50;

/* REFERENCABLE ROLE TYPES */

export const VENDOR_ROLE_VIDEOGRAPHER = 'videographer';
export const VENDOR_ROLE_PHOTOGRAPHER = 'photographer';
export const VENDOR_ROLE_CEREMONY_VENUE = 'ceremony-venue';
export const VENDOR_ROLE_RECEPTION_VENUE = 'reception-venue';

/* GENERIC SIZES */

export const MAX_DESKTOP_CONTENT_WIDTH = '1700px';
export const MAX_DESKTOP_NAVBAR_WIDTH = '911px';
export const DEFAULT_DAY_MONTH_BADGE_WIDTH = '100px';
export const DEFAULT_DAY_MONTH_BADGE_HEIGHT = '100px';

export const HSCROLL_CARD_SIZES_LAPTOP = 350;
export const HSCROLL_CARD_SIZES_TABLET = 340;
export const HSCROLL_CARD_SIZES_MOBILE_SMALL = 280;
export const HSCROLL_CARD_SIZES_MOBILE_MEDIUM = 300;
export const HSCROLL_CARD_SIZES_MOBILE_LARGE = 320;

/* SHADOWS AND EFFECTS */

export const NAVBAR_DROPDOWN_SHADOW = '1px 19px 17px 0px rgba(0,0,0,0.15)';
export const BACKDROP_BLUR_EFFECT_HEAVY = 'blur(30px)';
export const BACKDROP_BLUR_EFFECT_20 = 'blur(20px)';
export const BACKDROP_BLUR_EFFECT_LOW = 'blur(2px)';
export const BACKDROP_BLUR_EFFECT_BLOG_STORY_CARD = 'blur(20px)';

/* JWP Player IDs */

export const JW_PLAYER_ID_PREROLL = 'LDty3DAW';
export const JW_PLAYER_ID_NO_PREROLL = '24ane4MY';

/* ANIMATION TYPES, ANIMATION SPEEDS */

export const STANDARD_RADIUS = '40px';
export const FAST_ANIM_SPEED = '0.3s';
export const SUPER_FAST_ANIM_SPEED = '0.15s';
export const FASTER_ANIM_SPEED = '0.15s';
export const SEARCHBAR_HINT_ANIMATION = '0.3s';
export const ELEMENT_CARD_SHADOW_SPEED = '0.3s';
export const SIDE_BAR_APPEARANCE_ANIM = '0.4s';
export const SIDE_BAR_SWOOSH_PANE_SPEED = '0.5s';
export const FAST_EASE_OUT_ANIM = FAST_ANIM_SPEED + ' linear';
export const SUPER_FAST_EASE_OUT_ANIM = SUPER_FAST_ANIM_SPEED + ' linear';
export const SUPER_FAST_LINEAR_OUT_ANIM = SUPER_FAST_ANIM_SPEED + ' linear';
export const FAST_EASE_OUT_ANIM_FASTER = FASTER_ANIM_SPEED + ' linear';
export const PAGE_CONTENT_SLIDE_SPEED = '0.3s ease-in';
export const CARD_LOADING_IMG_FADEIN_SPEED = '0.5s ease-in';
export const CARD_IMAGE_ZOOM_ON_HOVER = '20s ease-in';
export const SIDEBAR_ACCORDION_SPEED = 300;
export const PHOTO_GALLERY_IMAGE_ANIMATION = 'scale(1.5) rotate(8deg)';
export const PHOTO_GALLERY_IMAGE_ANIMATION_SPEED_IN = '20s';
export const PHOTO_GALLERY_IMAGE_ANIMATION_SPEED_OUT = '15s';

/* OPACITY LEVELS */

export const DISABLED_OPACITY_LEVEL = 0.3;

/* card grid content types */

export const CARD_SECTION_VIDEO = 'video';
export const CARD_SECTION_BUSINESS = 'business';
export const CARD_SECTION_ARTICLE = 'article';
export const CARD_SECTION_VIBE = 'vibe';
export const CARD_SECTION_LOCATION = 'location';

/* Timing... */

export const TOOLTIP_SHOW_DELAY = 1000;
export const DEBOUNCE_TIME = 800;

/* POST TYPES */

export const LSTV_POST_TYPE_EVENT = 'wedding-ceremony-and-reception';
export const LSTV_POST_TYPE_BLOG = 'article';

/* ELEVATION - Standard page items*/

export const Z_INDEX_DEFAULT = '0';
export const Z_INDEX_5_OF_100 = '5';
export const Z_INDEX_6_OF_100 = '6';
export const Z_INDEX_7_OF_100 = '7';
export const Z_INDEX_8_OF_100 = '8';
export const Z_INDEX_9_OF_100_HEADER_OVERLAY = '9';
export const Z_INDEX_TOPMOST_IF_NONE_MODAL = '10';

/* ELEVATION - Floating elements, modals, veils (full-size page covers for loading purposes) */

export const Z_INDEX_UBER_MODAL_OR_VEIL = '100';

/* FONT SIZES AND LINE-HEIGHTS */

export const LINK_FONT_SIZE = '1rem';
export const LINK_LINE_HEIGHT = '1.2rem';
export const CARD_MAIN_DETAIL_FONT_SIZE = '1rem';
export const CARD_SECONDARY_DETAIL_FONT_SIZE = '0.8rem';
export const CARD_TAG_FONT_SIZE = '0.8rem';

/* PILLS */

export const DEFAULT_PILL_RIGHT_MARGIN = '5px';
export const DEFAULT_PILL_RADIUS = '20px';
export const DEFAULT_PILL_LEFT_PADDING = '10px';
export const DEFAULT_PILL_RIGHT_PADDING = '10px';
export const DEFAULT_PILL_MARGIN_BOTTOM = '5px';
export const DEFAULT_PILL_MARGIN_TOP = '0';
export const VIDEO_CARD_PILL_BACKGROUND = '#dcd3d366';

/* BUTTON SIZES */

export const DEFAULT_BUTTON_HEIGHT = '42.5px';

/* VENDOR TILE SIZES */

export const DEFAULT_VENDOR_TILE_WIDTH = '50%';
export const DEFAULT_VENDOR_TILE_HEIGHT = 50;
export const DEFAULT_VENDOR_TILE_HEIGHT_PX = DEFAULT_VENDOR_TILE_HEIGHT + 'px';

/* DEFAULT POST LOADER SIZE */

export const POST_PAGE_LOADER_SIZE = 30;

/* COMPOSITE STYLES */

/* TIMERS, TIMEOUTS, FIXED TIMES */

export const DEFAULT_CAROUSEL_AUTO_PAN_INTERVAL = 8000;
export const DEFAULT_CAROUSEL_AUTO_PAN_INTERVAL_OFFSET = 200;
export const MS_IN_SECOND = 1000;
export const VIDEO_PLAYBACK_TIME_MEASURE_BIN = 10 * MS_IN_SECOND;
export const REST_FAILURE_REATTEMPT_INTERVAL = 2 * MS_IN_SECOND;
export const REST_FAILURE_MAX_RETRIES = 5;

/* TOLERANCES */

export const CAROUSEL_SCROLL_TOLERANCE = 100;

/* REUSABLE STYLES */

export const STYLE_V_CENTER = {
  top: '50%',
  position: 'absolute',
  transform: 'translateY(-50%)',
};

export const TOP_STICKY_NAVBAR = {
  zIndex: Z_INDEX_9_OF_100_HEADER_OVERLAY,
};

export const TOP_STICKY_NAVBAR_NO_BLUR = {
  zIndex: Z_INDEX_9_OF_100_HEADER_OVERLAY,
};

export const STYLE_ROUND_CORNERS = {
  borderRadius: '10px',
};

export const VCenteredParagraph = styled.p`
  top: 50%;
  position: relative;
  transform: translateY(-50%);
`;

/* ACTION BAR THRESHOLDS */

export const ACTION_BAR_LIKES_DISPLAY_THRESHOLD = 10;
export const ACTION_BAR_SUBSCRIBER_DISPLAY_THRESHOLD = 10;
export const ACTION_BAR_VIEWS_DISPLAY_THRESHOLD = 100;
export const ACTION_BAR_SHARE_DISPLAY_THRESHOLD = 10;
export const ACTION_BAR_VIDEO_COUNT_DISPLAY_THRESHOLD = 10;
export const ACTION_BAR_PHOTO_COUNT_DISPLAY_THRESHOLD = 5;

/* ACTION BAR OWNER TYPES */

export const ACTION_BAR_OWNER_TYPE_VIDEO = 'video';
export const ACTION_BAR_OWNER_TYPE_ARTICLE = 'article';
export const ACTION_BAR_OWNER_TYPE_BUSINESS = 'business';
export const ACTION_BAR_OWNER_TYPE_VIBE = 'vibe';

/* COUNTRIES NOT TO SHOW A "STATE/PROVINCE" FOR */
export const display_no_state_countries = ['israel'];

/* LIMITS OF NON-CARD VENDOR/VIBE per-video display */

export const DEFAULT_VIDEO_DATA_POINTS_VIBE_LIMIT = 50;
export const DEFAULT_VIDEO_DATA_POINTS_VENDOR_LIMIT = 50;

/* RESPONSIVE definitions */

export const size = {
  mobileS: '320px',
  mobileSLimit: '374px',
  mobileM: '375px',
  mobileMLimit: '424px',
  mobileL: '425px',
  mobileDeviceWidthLimit: '767px',
  tablet: '768px',
  laptop: '1025px',
  laptopM: '1280px',
  laptopL: '1440px',
  desktop: '1800px',
  desktopL: '2560px',
  mobileResponsiveWidthLimit: '767px',
  tabletWidthLimit: '1024px',
  smallLaptopWidthLimit: '1439px',
  laptopWidthLimit: '1440px',
};

export const UserDevice = {
  mobileS: `(min-width: ${size.mobileS})`,
  mobileM: `(min-width: ${size.mobileM})`,
  mobileL: `(min-width: ${size.mobileL})`,
  tablet: `(min-width: ${size.tablet})`,
  laptop: `(min-width: ${size.laptop})`,
  laptopM: `(min-width: ${size.laptopM})`,
  laptopL: `(min-width: ${size.laptopL})`,
  desktop: `(min-width: ${size.desktop})`,
  desktopL: `(min-width: ${size.desktopL})`,
  isMobileS: `(min-width: ${size.mobileS}) and (max-width: ${size.mobileSLimit})`,
  isMobileM: `(min-width: ${size.mobileM}) and (max-width: ${size.mobileMLimit})`,
  isMobileL: `(min-width: ${size.mobileL}) and (max-width: ${size.mobileDeviceWidthLimit})`,
  isTinyMobile: `(max-width: ${size.mobileS})`,
  isWithinMobile: `(max-width: ${size.mobileDeviceWidthLimit})`,
  isMobile: `(min-width: ${size.mobileS}) and (max-width: ${size.mobileDeviceWidthLimit})`,
  isMobileOrTablet: `(min-width: ${size.mobileS}) and (max-width: ${size.tabletWidthLimit})`,
  isMobileOrTabletOrSmallLaptop: `(min-width: ${size.mobileS}) and (max-width: ${size.smallLaptopWidthLimit})`,
  isTablet: `(min-width: ${size.tablet}) and (max-width: ${size.tabletWidthLimit})`,
  isWithinTablet: `(max-width: ${size.tabletWidthLimit})`,
  isWithinLaptop: `(max-width: ${size.laptopWidthLimit})`,
  isLaptop: `(min-width: ${size.laptop}) and (max-width: ${size.laptopWidthLimit})`,
  isDesktop: `(min-width: ${size.desktop})`,
  notTablet: `(min-width: ${size.mobileS}) and (max-width: ${size.mobileDeviceWidthLimit}),
		(min-width: ${size.laptop})`,
};

/* DEFAULT VALUES OF STRINGS USED IN REDUX/LOCAL STORAGE */

export const FRONT_END_SETTINGS_HOME_PAGE_TITLE = 'Love Stories TV - Watch and Share Wedding Videos';

/* global FUNCTIONS */

export const APP_VERSION = () => process.env.APP_VERSION;
export const IS_DEV = () => {
  return !process.env.NEXT_PUBLIC_API_URL.includes('.com');
};
export const IS_STAGING = () => {
  return process.env.NEXT_PUBLIC_API_URL.includes('lstvtest.com');
};
export const IS_PRODUCTION = () => {
  return process.env.NEXT_PUBLIC_API_URL.includes('lovestoriestv.com');
};

/* Content Grid Defs */

export const CONTENT_GRID_CONTENT_TYPE_VIDEO = 'video';
export const CONTENT_GRID_CONTENT_TYPE_ARTICLE = 'article';
export const CONTENT_GRID_CONTENT_TYPE_BUSINESS = 'business';
export const CONTENT_GRID_CONTENT_TYPE_VIBE = 'vibe';
export const CONTENT_GRID_CONTENT_TYPE_ARTICLE_LOCATION = 'article_location';
export const CONTENT_GRID_CONTENT_TYPE_PHOTO = 'photo';

export const CONTENT_GRID_CONTENT_SEARCH_TYPE_VENDOR_TO_EVENT_STORY = 'business_to_video';
export const CONTENT_GRID_CONTENT_SEARCH_TYPE_VIBE_TO_EVENT_STORY = 'vibe_to_video';
export const CONTENT_GRID_CONTENT_SEARCH_TYPE_VIBE_TO_BUSINESS = 'vibe_to_business';
export const CONTENT_GRID_CONTENT_SEARCH_TYPE_LOCATION_TO_EVENT_STORY = 'location_to_video';
export const CONTENT_GRID_CONTENT_SEARCH_TYPE_FIXED_VENDOR_LIST = 'fixed_business_list';
export const CONTENT_GRID_CONTENT_SEARCH_TYPE_LOCATION_TO_BUSINESS = 'location_to_business';
export const CONTENT_GRID_CONTENT_SEARCH_TYPE_PREMIUM = 'premium';
export const CONTENT_GRID_CONTENT_SEARCH_TYPE_VENUE_TYPE_TO_VIDEO = 'venue_type_to_video';
export const CONTENT_GRID_CONTENT_SEARCH_TYPE_NONE = 'none';

export const CONTENT_GRID_LOCATION_SCOPE = 'worked_or_based_at';

export const CONTENT_GRID_CONTENT_SORT_METHOD_MOST_RECENT = 'most_recent';
export const CONTENT_GRID_CONTENT_SORT_METHOD_MOST_WATCHED_30D = 'most_watched_30d';
export const CONTENT_GRID_CONTENT_SORT_METHOD_MOST_WATCHED = 'most_watched';
export const CONTENT_GRID_CONTENT_SORT_METHOD_RANDOM = 'random';
export const CONTENT_GRID_CONTENT_SORT_METHOD_IMPORTANCE = 'importance';
export const CONTENT_GRID_CONTENT_SORT_METHOD_WEIGHT = 'weight';
export const CONTENT_GRID_CONTENT_SORT_METHOD_NONE = 'none';

// Limit to business roles
export const CONTENT_GRID_CONTENT_LIMIT_ROLE = 'venue';

/* INFINITY GRID MORE METHOD */

export const INFINITY_GRID_MORE_METHOD_BUTTON = 'button';
export const INFINITY_GRID_MORE_METHOD_SCROLL = 'scroll';
export const INFINITY_GRID_MORE_METHOD_PAGE = 'page';

/* CUSTOM CONTENT CONTEXTS */

export const CUSTOM_CONTENT_GRID_CONTEXT_VIDEO_PAGE_MORE_FROM_FILMMAKER = 'video-more-from-filmmaker';
export const CUSTOM_CONTENT_GRID_CONTEXT_MORE_FROM_BLOG = 'more-from-blog';
export const CUSTOM_CONTENT_GRID_CONTEXT_BLOG_VENDOR_CARD = 'blog-business-card';

/* CARD VERBOSITY LEVEL */

export const CONTENT_CARD_VERBOSITY_LEVEL_MAXIMUM = 'full';
export const CONTENT_CARD_VERBOSITY_LEVEL_MEDIUM = 'card';
// ? not actually sure what this should be but the numbers that were previously here don't do anything
export const CONTENT_CARD_VERBOSITY_LEVEL_MINIMUM = 'card';
export const CONTENT_CARD_VERBOSITY_LEVEL_SLUG = 'slug';
export const CONTENT_CARD_VERBOSITY_LEVEL_SEARCH_HINT = 'search_hint';

// let's migrate to using these obviously more explicit verbosity constants
export const VERBOSITY_LEVEL_PAGE = 'page';
export const VERBOSITY_LEVEL_CARD = 'card';
export const VERBOSITY_LEVEL_SLUG = 'slug';

/* SVG IMAGES */

export const DIAGONAL_BACKGROUND =
  "url(\"data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='100%25' height='100%25'%3E%3Cdefs%3E%3Cpattern id='stripes' patternUnits='userSpaceOnUse' width='7' height='6' patternTransform='rotate(45)'%3E%3Cline x1='1' y='0' x2='1' y2='7' stroke='%23eeeeee66' stroke-width='1.0' /%3E%3C/pattern%3E%3C/defs%3E%3Crect width='100%25' height='100%25' fill='%23fffeea00' /%3E%3Crect width='100%25' height='100%25' fill='url(%23stripes)' /%3E%3C/svg%3E\")";
export const DIAGONAL_SUBLE_BACKGROUND =
  "url(\"data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='100%25' height='100%25'%3E%3Cdefs%3E%3Cpattern id='stripes' patternUnits='userSpaceOnUse' width='7' height='6' patternTransform='rotate(45)'%3E%3Cline x1='1' y='0' x2='1' y2='7' stroke='%2333333309' stroke-width='1.0' /%3E%3C/pattern%3E%3C/defs%3E%3Crect width='100%25' height='100%25' fill='%23fffeea00' /%3E%3Crect width='100%25' height='100%25' fill='url(%23stripes)' /%3E%3C/svg%3E\");";

/* COMPOSITE CONTENT COMONENTS */

export const COMPOSITE_CONTENT_LEGACY_HTML = 'legacy_html';
export const COMPOSITE_CONTENT_VIDEO = 'video';

/* NON REAL TIME USER EVENTS AND BUFFER TIME SCOPE */

export const USER_NRT_REPORT_CARD_IMPRESSION = 'card_impression';
export const USER_NRT_REPORT_VENDOR_CARD_MENTION_IMPRESSION = 'business_card_mention_impression';

export const USER_NRT_REPORT_BUFFER_SCOPE_DAY = 'day';
export const USER_NRT_REPORT_BUFFER_SCOPE_WEEK = 'week';
export const USER_NRT_REPORT_BUFFER_SCOPE_MONTH = 'month';

export const USER_TYPE_VENDOR_TEAM_MEMBER = 'business_team_member';
export const USER_TYPE_VENDOR_TEAM_MEMBER_ONBOARDING = 'business_team_member_onboarding';
export const USER_TYPE_CONSUMER = 'consumer';
