import React, { useEffect, useState, createContext, useContext } from 'react';
import PublicContentService from '~/api/services/publicContentService';

const DirectoryContext = createContext({
  directories: [],
  roleDirectories: [],
});

const useDirectories = () => {
  return useContext(DirectoryContext);
};

const useProvideDirectories = () => {
  const [directories, setDirectories] = useState([]);
  const [roleDirectories, setRoleDirectories] = useState([]);

  useEffect(() => {
    fetchDirectories();
  }, []);

  const fetchDirectories = async () => {
    const [dirs, roleDirs] = await Promise.all([
      PublicContentService.getSearchDirectories(),
      PublicContentService.getSearchRoleDirectories(),
    ]);
    setDirectories(dirs);
    setRoleDirectories(roleDirs);
  };

  return {
    directories,
    roleDirectories,
  };
};

function DirectoryProvider({ children }) {
  const search = useProvideDirectories();
  return <DirectoryContext.Provider value={search}>{children}</DirectoryContext.Provider>;
}

export { useDirectories, DirectoryProvider };
