import classNames from 'classnames';
import NextLink from 'next/link';
import React from 'react';
import { useTrackEvent } from '~/globals/trackEvent';
import { useNavBar } from '~/hooks/useNavBar';

export type LSTVLinkProps = Omit<
  {
    to: string;
    /**
     * When `undefined`, no styles are added, so presentation-wise the component
     * is equivalent to `a`. When `false`, we add class `lstvLinkNoStyle`,
     * and when `true`, a class `lstvLink`. These classes are globally styled in `globals.scss`
     * (`lstvLinkNoStyle` only has `text-decoration: none !important;`).
     *
     * @deprecated If we need reusable styling, we can create a separate component for that.
     */
    styled?: boolean;
    prefetch?: boolean;
    blankTarget?: boolean;
  } & React.AnchorHTMLAttributes<HTMLAnchorElement>,
  'href'
>;

// eslint-disable-next-line react/display-name
const LSTVLink = React.forwardRef<HTMLAnchorElement, LSTVLinkProps>(
  ({ to = '/', className, prefetch = false, onClick, styled, blankTarget, children, ...rest }, ref) => {
    const { setModal } = useNavBar();

    let rc;
    let link = to;

    if (!/^(https?:|\/|mailto:|tel:)/.test(link)) {
      link = `/${link}`;
    }

    const trackEvent = useTrackEvent();

    const handleClick = (event) => {
      const url = new URL(link, document.baseURI);
      const isInternal = url.origin === document.location.origin;

      setModal('none');

      trackEvent('click_link', {
        to_url: link,
        type: link.startsWith('mailto:')
          ? 'mailto'
          : link.startsWith('tel:')
          ? 'tel'
          : isInternal
          ? 'internal'
          : 'external',
        ...(isInternal ? { to_url_path: url.pathname } : {}),
      });

      return onClick?.(event);
    };

    const finalClassName = classNames(
      {
        lstvLink: styled,
        lstvLinkNoStyle: styled === false,
      },
      className
    );

    // It would probably make sense to change this condition to the same one that's used by analytics,
    // i.e. `!isExternal`.
    if (link.startsWith('/')) {
      rc =
        // this is dumb but if you send prefetch=true you get deprecation warnings
        prefetch ? (
          <NextLink href={link}>
            <a className={finalClassName}  onClick={handleClick} {...{ ref }} {...rest}>
              {children}
            </a>
          </NextLink>
        ) : (
          <NextLink href={link} prefetch={false}>
            <a className={finalClassName} target={blankTarget ? "_blank": undefined} onClick={handleClick} {...{ ref }} {...rest}>
              {children}
            </a>
          </NextLink>
        );
    } else {
      rc = (
        <a
          className={finalClassName}
          rel="noreferrer"
          target="_blank"
          href={link}
          onClick={handleClick}
          {...{ ref }}
          {...rest}
        >
          {children}
        </a>
      );
    }
    return rc;
  }
);

export default LSTVLink;
