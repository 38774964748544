import styled from 'styled-components';
import theme from '~/styles/styledComponentsTheme';

export const Container = styled.div`
  @media ${theme.breakpoints.laptop} {
    position: relative;
  }
`;

export const IconContainer = styled.div`
  position: absolute;
  top: 50%;
  right: 8px;
  transform: translateY(-50%);
  display: flex;
  justify-content: center;
  align-items: center;
  height: 40px;
  width: 40px;

  svg {
    height: 20px;
    width: 20px;
  }
`;

export const InputContainer = styled.div`
  position: relative;
  transition: all 0.3s ease;

  * {
    box-sizing: border-box;
  }

  @media ${theme.breakpoints.isMobileOrTablet} {
    position: relative;
    display: flex;
  }
`;

export const SearchInput = styled.input`
  box-sizing: border-box;
  -webkit-appearance: none;
  height: 48px;
  width: 100%;
  padding: 0 0 0 15px;
  border: 1px solid ${theme.midGrey};
  border-radius: 10px;
  background: #f9f9f9;
  font-weight: 500;
  transition: border-color 200ms ease, background-color 200ms ease;

  &::placeholder {
    color: #323233;
    opacity: 1;
    font-weight: 400;
    font-family: Calibre;
    font-size: 20px;
  }

  :hover,
  :focus {
    border-color: ${theme.darkerGrey};
    background-color: ${theme.lightGrey};

    &::placeholder,
    &::-moz-placeholder {
      color: ${theme.black};
    }
  }
`;
