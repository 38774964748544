import styled from 'styled-components';
import { ButtonTopNavTextStyles } from '~/components/typography';
import { PRIMARY_COLOR, UserDevice } from '~/globals';
import theme from '~/styles/styledComponentsTheme';
import LSTVLink from '~/components/LSTVLink';

const { isMobile, tablet, laptop, laptopM, laptopL, isWithinLaptop } = UserDevice;

export const HeaderBarContainer = styled.nav`
  margin: 0 auto;
  position: relative;
  z-index: 1000;
  background: ${theme.white};
  box-shadow: 0px 1px 0px 0px #00000040;

  @media ${isMobile} {
    transition: background-color 300ms linear;
    height: ${theme.headerHeightMobile};
  }

  @media ${laptop} {
    height: auto;
    flex-direction: column;
    justify-content: flex-start;
  }
`;

export const DesktopLogoLink = styled(LSTVLink)`
  display: none;

  @media ${laptopM} {
    padding-right: 24px;
    max-width: 140px;
    display: flex;
  }
`;

export const StyledLink = styled(LSTVLink)<{ $selected?: boolean }>`
  height: 100%;
  display: flex;
  align-items: center;
  padding: 2px 10px 0 10px;
  border-top: 5px solid transparent;
  border-bottom: 5px solid ${(props) => (props.$selected ? '#323233' : 'transparent')};
  text-decoration: none !important;

  :hover {
    border-bottom: 5px solid #323233;
  }

  @media ${isWithinLaptop} {
    height: 75px;
  }
`;

export const StyledButton = styled.div`
  cursor: pointer;
  color: ${theme.black};
  font-size: 17px;
`;

export const SearchBarWrapper = styled.div`
  width: 100%;
  margin-right: 24px;

  @media ${laptop} {
    max-width: 350px;
  }
  @media ${laptop} {
    margin-right: 10px;
    width: auto;
  }
  @media ${laptopM} {
    margin-right: 24px;
    width: 100%;
  }
  @media ${laptopL} {
    margin-right: 100px;
  }
  @media ${isMobile} {
    margin-right: 32px;
  }
`;

export const HeaderLinks = styled.div`
  display: none;
  @media ${laptop} {
    display: flex;
    height: 100%;
  }
`;

export const SignInButtons = styled.div`
  display: flex;
  align-items: center;
  margin-left: 16px;
`;

export const SignInButton = styled.div`
  display: none;
  cursor: pointer;
  font-size: 17px;
  font-weight: 600;
  background-color: #323233;
  white-space: nowrap;
  color: #ffffff;
  width: 84px;
  height: 40px;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;

  @media ${laptop} {
    display: flex;
  }
`;
export const MoreModalSignInButton = styled(SignInButton)`
  width: 100%;
  margin-bottom: 14px;
`;

export const UserMenuItem = styled.div<{ $hideOnMobile?: boolean }>`
  font-size: 0.937rem;
  line-height: 21.42px;
  padding: 14px 12px;
  cursor: pointer;
  ${ButtonTopNavTextStyles}
  display: ${(props) => (props.$hideOnMobile ? 'none' : 'block')};

  :hover {
    color: ${PRIMARY_COLOR};
  }

  @media ${laptopL} {
    display: block;
    border-bottom: 1px solid ${(props) => props.theme.lightGrey};
  }
`;

export const NonLinkMenuItem = styled.div`
  border-top: 1px solid #e4e6e9;
  @media ${laptopL} {
    border: none;
  }
  font-size: 0.937rem;
  line-height: 21.42px;
  padding: 14px 12px;
  cursor: pointer;
  ${ButtonTopNavTextStyles}
`;

export const ProfileMenuItem = styled(UserMenuItem)`
  display: flex;
  align-items: center;
  padding: 16px 12px;

  & > div:nth-child(2) {
    margin-left: 10px;
    line-height: 1rem;
    font-size: 0.937rem;
    max-width: 200px;

    & small {
      font-size: 0.8rem;
      line-height: 0.875rem;
      color: #9b9b9b;
    }
  }
`;

export const DropdownMenu = styled.div`
  background: #fff;
  position: relative;
  border-radius: 3px;
  display: flex;
  flex-direction: column;

  @media ${tablet} {
    filter: drop-shadow(0 0 8px rgba(169, 169, 169, 0.25));
    min-width: 232px;
  }
`;

export const HeaderContainer = styled.div`
  margin: 0 auto;
  width: 100%;
  max-width: 1440px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 20px;

  @media ${laptopL} {
    padding: 0 32px;
    height: 75px;
  }
`;

export const MobileLogoLink = styled(LSTVLink)<{ $large?: boolean }>`
  display: flex;
  flex-shrink: 0;
  align-items: center;
  justify-content: center;
  height: calc(${theme.headerHeightMobile} - 40px);
  width: ${(props) => (props.$large ? '175px' : '50px')};
  margin-right: 20px;

  @media ${laptop} {
    margin-right: 10px;
  }
  @media ${laptopM} {
    display: none;
  }
`;

export const HeaderModal = styled.div`
  position: fixed;
  left: 0;
  width: 100%;
  padding: 20px;
  padding-bottom: 60px;
  background: white;
  line-height: 100%;
  margin-top: 1px;
  border-bottom: 2px solid #999;
  height: calc(100vh - 115px);
  overflow: scroll;

  @media ${laptopM} {
    padding-bottom: 20px;
    padding-top: 0;
    height: auto;
    overflow: hidden;
  }
  @media ${laptopL} {
    padding: 48px 48px 72px 72px;
  }
`;
export const HeaderModalInner = styled.div`
  max-width: 1440px;
  margin: 15px auto 0 auto;
`;

export const VendorModalLayout = styled.div`
  display: flex;
  flex-direction: column;

  @media ${laptopM} {
    max-width: 1024px;
    margin: 0 auto;
  }
  @media ${laptopL} {
    flex-direction: row;
`;
export const VendorModalLeftSide = styled.div`
  width: auto;
  margin-bottom: 24px;

  @media ${laptopL} {
    width: 267px;
    margin-right: 124px;
  }
`;
export const VendorModalLeftHeader = styled.div`
  font-weight: 500;
  font-size: 48px;
  line-height: 100%;
`;
export const VendorModalLeftText = styled.div`
  font-size: 21px;
  line-height: 100%;
  margin-top: 16px;
  margin-bottom: 16px;

  @media ${laptopL} {
    margin-bottom: 48px;
  }
`;
export const HeaderModalTitle = styled.div`
  font-size: 30px;
  line-height: 100%;
  margin-bottom: 24px;
`;

export const DirectoryGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);

  @media ${laptop} {
    grid-template-columns: repeat(4, 1fr);
  }
  @media ${laptopM} {
    grid-template-columns: repeat(6, 1fr);
  }
  @media ${laptopL} {
    grid-template-columns: repeat(4, 1fr);
  }
`;
export const DirectoryTile = styled(LSTVLink)`
  display: flex;
  flex-direction: column;
  margin: 0 auto 24px auto;
  width: 155px;
  text-decoration: none !important;

  @media ${laptopL} {
    width: 160px;
    margin-right: 24px;
  }
`;
export const DirectoryImage = styled.div<{ imageUrl?: string; color: string }>`
  border-radius: 10px;
  background: ${(props) =>
    props.imageUrl
      ? 'linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.5)), url(' + props.imageUrl + ') center/cover'
      : props.color};
  margin-bottom: 5px;
  width: 155px;
  height: 155px;

  @media ${laptopM} {
    width: 100%;
    height: 120px;
  }
`;

export const MoreModalFirstCategory = styled.div`
  font-size: 20px;
  margin-bottom: 16px;
`;
export const MoreModalCategory = styled.div`
  font-size: 20px;
  margin: 42px 0 16px 0;
`;
export const MoreModalLink = styled(LSTVLink)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 28px;
  margin-bottom: 16px;
  text-decoration: none !important;
`;

export const DesktopOnly = styled.div`
  display: none;
  @media ${laptop} {
    display: block;
  }
`;
export const MobileOnly = styled.div`
  @media ${laptop} {
    display: none;
  }
`;

export const SubscribeContainer = styled.div`
  margin: 20px 0;

  > form {
    display: flex;
    margin-top: 30px;
  }

  > div {
    margin: 5px 0 20px 0;
    color: ${theme.grey_1_0};
  }
`;
