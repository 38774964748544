import { FontAwesomeIcon, FontAwesomeIconProps } from '@fortawesome/react-fontawesome';
import { identity } from 'lodash';
import Image from 'next/image';
import React from 'react';
import styled from 'styled-components';
import LSTVButton, { LSTVButtonProps } from '~/components/LSTVButton';
import LSTVLink, { LSTVLinkProps } from '~/components/LSTVLink';
import loadingGif from '~/images/button-loading-white.gif';

export enum CtaSize {
  Small,
  Medium,
  Large,
}

export enum CtaColorScheme {
  /**
   * Highlight color as background.
   */
  Highlight,
  /**
   * Uses currentColor + transparent background.
   */
  Outline,
  /**
   * Uses black fill as background.
   */
  Dark,
  /**
   * No background or border
   */
  Plain,
}

/**
 * Separating them out from other props just so they can be made required
 * internally and optional externally.
 */
interface PropsWithDefaults {
  $size: CtaSize;
  $colorScheme: CtaColorScheme;
}

const addDefaults = <T extends Partial<PropsWithDefaults>>({
  $size = CtaSize.Medium,
  $colorScheme = CtaColorScheme.Highlight,
  ...rest
}: T) => ({
  ...rest,
  $size,
  $colorScheme,
});

const commonStyles = <Component extends typeof LSTVLink | typeof LSTVButton>(Component: Component) => styled(
  Component
)<PropsWithDefaults>`
  display: inline-flex;
  border-radius: 8px;
  border: 1px solid
    ${(props) =>
      props.$colorScheme === CtaColorScheme.Highlight
        ? 'transparent'
        : props.$colorScheme === CtaColorScheme.Outline
        ? 'currentColor'
        : props.$colorScheme === CtaColorScheme.Dark
        ? 'transparent'
        : props.$colorScheme === CtaColorScheme.Plain
        ? 'transparent'
        : identity<never>(props.$colorScheme)};
  background: ${(props) =>
    props.$colorScheme === CtaColorScheme.Highlight
      ? props.theme.highlight_7_0
      : props.$colorScheme === CtaColorScheme.Outline
      ? 'transparent'
      : props.$colorScheme === CtaColorScheme.Dark
      ? props.theme.highlight_2_0
      : props.$colorScheme === CtaColorScheme.Plain
      ? 'transparent'
      : identity<never>(props.$colorScheme)};
  color: ${(props) =>
    props.$colorScheme === CtaColorScheme.Highlight
      ? '#fff'
      : props.$colorScheme === CtaColorScheme.Outline
      ? 'currentColor'
      : props.$colorScheme === CtaColorScheme.Dark
      ? props.theme.white
      : props.$colorScheme === CtaColorScheme.Plain
      ? 'currentColor'
      : identity<never>(props.$colorScheme)} !important;
  font-size: 17px;
  font-weight: 500;
  height: ${(props) =>
    props.$size === CtaSize.Large
      ? 53
      : props.$size === CtaSize.Medium
      ? 47
      : props.$size === CtaSize.Small
      ? 'auto'
      : identity<never>(props.$size)}px;
  width: ${(props) =>
    props.$size === CtaSize.Large
      ? 277
      : props.$size === CtaSize.Medium
      ? 192
      : props.$size === CtaSize.Small
      ? 'auto'
      : identity<never>(props.$size)}px;
  align-items: center;
  justify-content: center;
  ${(props) => (props.$size === CtaSize.Small ? 'padding: 12px 22px' : '')}
`;

const StyledIcon = styled(FontAwesomeIcon)`
  margin-left: 10px;
  font-size: 23px;
`;

interface ContentProps {
  caption?: string;
  icon?: FontAwesomeIconProps['icon'];
  loading?: boolean;
}

const Content: React.FC<ContentProps> = ({ caption, icon, loading, children }) => {
  if (loading) return <Image src={loadingGif} width={40} height={40} />;
  if (caption)
    return (
      <>
        {caption}
        {icon && <StyledIcon {...{ icon }} />}
      </>
    );
  return <>{children}</>;
};

const StyledLSTVLink = styled(commonStyles(LSTVLink))`
  text-decoration: none;
`;

export type CtaLinkProps = LSTVLinkProps &
  Partial<PropsWithDefaults> & { caption?: string; icon?: FontAwesomeIconProps['icon'] };

// eslint-disable-next-line react/display-name
export const CtaLink = React.forwardRef<HTMLAnchorElement, CtaLinkProps>(
  ({ caption, icon, children, ...rest }, ref) => (
    <StyledLSTVLink {...{ ref }} {...addDefaults(rest)}>
      <Content {...{ caption, icon }}>{children}</Content>
    </StyledLSTVLink>
  )
);

const StyledLSTVButton = styled(commonStyles(LSTVButton))`
  cursor: pointer;
  &:disabled {
    opacity: 0.5;
    cursor: inherit;
  }
`;

export type CtaButtonProps = LSTVButtonProps &
  Partial<PropsWithDefaults> & {
    caption?: string;
    icon?: FontAwesomeIconProps['icon'];
    loading?: boolean;
  };

/**
 * Event action is set to caption by default.
 */
// eslint-disable-next-line react/display-name
export const CtaButton = React.forwardRef<HTMLButtonElement, CtaButtonProps>(
  ({ caption, icon, loading, eventAction, children, ...rest }, ref) => (
    <StyledLSTVButton eventAction={eventAction ?? caption} {...{ ref }} {...addDefaults(rest)}>
      <Content {...{ caption, icon, loading }}>{children}</Content>
    </StyledLSTVButton>
  )
);
