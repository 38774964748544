import { useCallback } from 'react';
import NextHead from 'next/head';
import { useRouter } from 'next/router';
import { FRONT_END_SETTINGS_HOME_PAGE_TITLE } from '~/globals';
import { convertSecondsToIso8601Duration } from '~/utils/date';

type Props = {
    title?: string;
    /**
     * Title for social media cards - if absent, will fall back to `title`.
     */
    titleOg?: string;
    /**
     * Will be truncated if necessary.
     */
    description?: string;
    image?: string;
    /**
     * pathname + optional query.
     */
    url?: string;
    article?: {
        /**
         * In ISO format like 2020-05-02.
         */
        publishedTime?: string;
    };
    video?: any; // TSFIXME
    keywords?: string;
    includeSchemaOrgVideoObject?: Boolean;
};

const MAX_DESCRIPTION_LENGTH = 300;

/**
 * Page-specific <head> contents. One instance of this component is included in
 * {@link BaseHead}, with current page URL used as canonical URL, but a page can
 * override the default params by creating another instance.
 */
export const CustomHead = ({
    title,
    titleOg,
    description,
    image,
    url,
    article,
    video,
    keywords,
    includeSchemaOrgVideoObject,
}: Props) => {
    let absUrl;

    let urlCopy = url;
    if (url === '/') {
        absUrl = 'https://lovestoriestv.com';
    } else {
        if (url !== undefined && url.startsWith('/') && url.length > 1) urlCopy = url.substring(1);
        absUrl = url !== undefined ? 'https://lovestoriestv.com/' + urlCopy : undefined;
    }

    let truncatedDescription = description;
    if (description?.length > MAX_DESCRIPTION_LENGTH) {
        truncatedDescription = `${description.slice(0, MAX_DESCRIPTION_LENGTH - 3)}...`;
    }
    const finalTitleOg = titleOg ?? title;

    // This doesn't actually match the exact height for many images, but FB seems fine with it. Ideally
    // we should get exact dimensions from the server.
    const size = { width: '640', height: '360' };
    const finalImage = image ? image.replace('-orig.', '-dsk.') : image;

    const setupSchemaOrgVideoObject = useCallback(() => {
        const duration = convertSecondsToIso8601Duration(
            (video.videosSources && video.videosSources[0]?.duration) || 0
        );

        return {
            '@context': 'https://schema.org',
            '@type': 'VideoObject',
            name: title,
            description: truncatedDescription || '',
            duration,
            thumbnailUrl: image.replace('-orig', '-dsk'),
            uploadDate: new Date(video.created_at).toISOString(),
            embedUrl: absUrl,
            interactionCount: video.views,
        };
    }, []);

    // Next.js or React - not sure which - automatically take care of de-duping
    // <meta>'s with the same name and <title>, but for the rest there has to be a key specified.
    return (
        <NextHead>
            {/* General tags */}
            {title && <title>{title}</title>}
            {truncatedDescription && <meta name="description" content={truncatedDescription} />}
            {finalImage && <meta name="image" content={finalImage} />}
            {absUrl && <link rel="canonical" href={absUrl} key="canonical-url" />}
            {keywords && <meta name="keywords" content={keywords} />}

            {/* OpenGraph tags */}
            {absUrl && <meta property="og:url" content={absUrl} key="og:url" />}
            {finalTitleOg && <meta property="og:title" content={finalTitleOg} key="og:title" />}
            {description && <meta property="og:description" content={truncatedDescription} key="og:description" />}
            {finalImage && <meta property="og:image" content={finalImage} key="og:image" />}
            <meta property="og:image:width" content={size.width} key="og:image:width" />
            <meta property="og:image:height" content={size.height} key="og:image:height" />
            <meta property="og:type" content={article ? 'article' : video ? 'video.movie' : 'website'} key="og:type" />
            {article?.publishedTime && <meta property="article:published_time" content={article.publishedTime} />}

            {/* Twitter Card tags */}
            {finalTitleOg && <meta name="twitter:title" content={finalTitleOg} />}
            {truncatedDescription && <meta name="twitter:description" content={truncatedDescription} />}
            {finalImage && <meta name="twitter:image" content={finalImage} />}

            {includeSchemaOrgVideoObject && (
                <script
                    type="application/ld+json"
                    key="video-object"
                    dangerouslySetInnerHTML={{
                        __html: JSON.stringify(setupSchemaOrgVideoObject()),
                    }}
                ></script>
            )}
        </NextHead>
    );
};

/**
 * Site-wide <head> contents. Should only be instantiated once.
 */
export const BaseHead = () => {
    const fbAppID = '1202364220127674';
    // const gaTrackingId = 'UA-66927174-1';
    const gaTrackingId = 'G-KM48JQ05F1';
    const router = useRouter();

    return (
        <>
            <NextHead>
                {/* General tags */}
                <link rel="icon" href="/favicon.ico" />
                <meta name="google-site-verification" content="aZtM86v2fpEiv2ZV2vBP95b68m9s763JMpsERLRBYPo" />
                <meta charSet="utf-8" />
                {/* OpenGraph tags */}
                <meta property="fb:app_id" content={fbAppID} />
                {/* Twitter Card tags */}
                <meta name="twitter:card" content="summary_large_image" />
                <meta name="twitter:creator" content={'@LoveStoriesTV'} />
                KM48JQ05F1
                {/* TODO: GTM, Userback, Intercom etc */}
                <script async src={`https://www.googletagmanager.com/gtag/js?id=${gaTrackingId}`}></script>
                <script
                    dangerouslySetInnerHTML={{
                        __html: `
            window.dataLayer = window.dataLayer || [];
            function gtag(){dataLayer.push(arguments);}
            gtag('js', new Date());
            gtag('config', '${gaTrackingId}');
          `,
                    }}
                />
                {/* AdSense: Remove this line to eliminate ads from the site */}
                <script
                    async
                    src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-8277122527317510"
                    crossOrigin="anonymous"
                ></script>
            </NextHead>
            <CustomHead
                title={FRONT_END_SETTINGS_HOME_PAGE_TITLE}
                description="The New Way To Plan Your Wedding."
                image="https://cdn.lovestoriestv.com/photos/og.jpg"
                url={router.asPath}
            />
        </>
    );
};
