import { BeatLoader } from 'react-spinners';
import { VendorsIcon } from '~/components/InlineSVG';
import useHover from '~/hooks/useHover';
import theme from '~/styles/styledComponentsTheme';
import { shorthandValue } from '~/utils/LSTVUtils';
import NoResults from './NoResults';
import * as S from './FreeTextResults.styles';
import { useTrackEvent } from '~/globals/trackEvent';
import { useSearch } from '~/hooks/useSearch';

const ResultItem = ({ item, onOpen }) => {
  const { name, display_location, content_type, weight, slug, hide_location } = item;
  const isDirectory = content_type === 'business';
  const [ref, hovered] = useHover();

  return (
    <S.ItemLink to={isDirectory ? `/${slug}` : `/business/${slug}`} onClick={() => onOpen?.(item)}>
      <S.Item key={name} ref={ref as any}>
        {isDirectory && (
          <S.Icon>
            <VendorsIcon fillColor={theme.darkerGrey} />
          </S.Icon>
        )}
        <S.ItemText $isDirectory={isDirectory}>{name}</S.ItemText>
        {display_location && display_location.toLowerCase() !== 'none' && !hide_location && (
          <S.ItemLocation>{display_location}</S.ItemLocation>
        )}
        {content_type === 'business' && weight > 0 && <S.ItemLocation>{shorthandValue(weight)}</S.ItemLocation>}
      </S.Item>
    </S.ItemLink>
  );
};

const FreeTextResults = ({ data, noResults, isLoading, query }) => {
  const trackEvent = useTrackEvent();
  const { closeResults } = useSearch();

  if (isLoading) {
    return (
      <S.Loading>
        <BeatLoader size={24} color={theme.primaryYellow} loading />
      </S.Loading>
    );
  }

  const onOpen = (item) => {
    closeResults();
    trackEvent('search', {
      query,
      result_is_directory: item.content_type === 'business',
      result: item.slug,
      content_type: 'business',
    });
  };

  if (data?.length) {
    return (
      <S.List>
        {data.map((item) => (
          <ResultItem key={item.name} item={item} onOpen={onOpen} />
        ))}
      </S.List>
    );
  }

  if (noResults) return <NoResults />;

  return null;
};

export default FreeTextResults;
