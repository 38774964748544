export function isValidDate(d: any) {
  const isNumber = !isNaN(d);
  const valid = d instanceof Date && isNumber;
  return valid;
}

// TODO add setup for tests
export const convertSecondsToIso8601Duration = (seconds: number = 0) => {
  const s = seconds % 60;
  const m = Math.floor(seconds / 60) % 60;
  const h = Math.floor(seconds / (60 * 60));

  let duration = 'PT';

  duration += h ? `${h}H` : '';
  duration += m ? `${m}M` : '';
  duration += s ? `${s}S` : '';

  return duration;
};

export const formatDate = (date) => {
  const formatter = new Intl.DateTimeFormat('en', { month: 'long', year: 'numeric' });
  return formatter.format(new Date(date));
};
