// Also defined in globals/globals.js to keep existing patterns.
import breakpoints from '~/globals/breakpoints';

const theme = {
  main: 'mediumseagreen',

  highlight_1_0: '#DDD6D0',
  highlight_2_0: '#3A3D34',
  highlight_7_0: '#B86747',
  highlight_6_0: '#E3A887',
  highlightColor: '#9980fd',
  lightHighlightColor: '#D9D0FF',
  primaryYellow: '#f9f39f',
  grey_1_0: '#606770',
  grey_2_0: '#cdd0d4',
  grey_3_0: '#e4e6e9',
  grey_4_0: '#f0f2f5',
  grey_5_0: '#f7f8f9',
  grey_6_0: '#f0f2f5',
  grey_7_0: '#999fa7',

  lightGrey: '#f9f9f9',
  midGrey: '#ececec',
  darkGrey: '#c9c4c4',
  placeholderGrey: '#cdc9c9',
  lighterGrey: '#F3F3F3',
  darkerGrey: '#9B9B9B',
  blueGrey: '#F0F2F5',
  coldGrey: '#606770',

  black: '#323233',
  darkNightBlack: '#323233',
  red: '#FF9D9D',
  white: '#ffffff',
  translucentWhite: 'rgba(255, 255, 255, 0.76)',
  fontWeightSemibold: '500',

  textAndSvgBlack: '#0C098A',
  cardDropShadow: '#bababa',
  business_role_family_color: {
    beauty: '#EBC7B7',
    jewelry: '#ff8b3d',
    decor_rentals: '#EBA900',
    fashion: '#F16565',
    florals: '#FF7A00',
    florists: '#FF7A00',
    food_beverage: '#790074',
    gifts: '#85E6BD',
    music_entertainment: '#53B7FF',
    music: '#53B7FF',
    officiant_ceremony: '#241C78',
    officiant: '#241C78',
    other: '#F4DB75',
    planning_design: '#8AEC81',
    planning_and_design: '#8AEC81',
    signage_stationery: '#CA9AD6',
    transportation: '#2D0AB9',
    venue: '#FF80A6',
    venues: '#FF80A6',
    video_photo: '#10D3EA',
    videographers: '#10D3EA',
    photography: '#10D3EA',
  },
  breakpoints,
  zIndex: {
    mobileFooter: 8,
    specifcPageFooter: 9,
    uberModalOrVeil: '100',
    searchContent: 20,
    modalOverlay: '100',
    stickySideBar: 9,
    dropdown: 9,
  },
  headerHeightMobile: '75px',
  headerHeightDesktop: '124px',
};

export default theme;

type ThemeType = typeof theme;

// A variation of what's suggested here https://styled-components.com/docs/api#typescript
declare module 'styled-components' {
  export interface DefaultTheme extends ThemeType {}
}
