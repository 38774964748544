import React from 'react';
import { fadeIn } from 'react-animations';
import styled, { keyframes } from 'styled-components';
import { useSearch } from '~/hooks/useSearch';
import theme from '~/styles/styledComponentsTheme';
import FreeTextResults from './FreeTextResults';
import LocationResults from './LocationResults';
import SuggestedCards from './SuggestedCards';

const fadeAnimation = keyframes(fadeIn);

// simple search results are diff't enough from standard
// that it's worth creating it's own container even if we're duplicating a bit.
const SimpleContainer = styled.div`
  position: absolute;
  z-index: ${theme.zIndex.searchContent};
  background: white;
  top: 80px;
  left: 20px;
  width: calc(100vw - 40px);
  max-height: 400px;
  overflow-y: auto;
  border-radius: 10px;
  box-shadow: 0px 0px 6px rgba(186, 186, 186, 5);
  animation: 0.3s ${fadeAnimation} ease-in;

  @media ${theme.breakpoints.laptop} {
    left: 0;
    top: 50px;
    width: 505px;
  }

  @media ${theme.breakpoints.laptopM} {
    width: 505px;
  }
`;

const Container = styled.div<{ $halfWidth?: boolean }>`
  @media ${theme.breakpoints.isMobileOrTablet} {
    margin: 24px 0 0;
  }

  @media ${theme.breakpoints.laptop} {
    position: absolute;
    background: white;
    top: 70px;
    right: 0;
    z-index: ${theme.zIndex.searchContent};
    overflow-y: auto;
    width: ${(props) => (props.$halfWidth ? '50%' : '100%')};
    border-radius: 10px;
    box-shadow: 0px 0px 6px rgba(186, 186, 186, 5);
    animation: 0.3s ${fadeAnimation} ease-in;
    transition: width 0.2s ease-in-out;
  }
`;

const Results = () => {
  const { resultsTypes, query, results, fields, currentFocusedField, handleSelectLocation, isLoading } = useSearch();
  const { type, data } = results || {};

  // simple search won't show cards or need to worry about a state where
  // it's showing location results.
  if (currentFocusedField === fields.SIMPLE_SEARCH) {
    return (
      <FreeTextResults data={data} isLoading={isLoading} noResults={type === resultsTypes.NO_RESULTS} query={query} />
    );
  }

  const componentByType = {
    [resultsTypes.NO_INPUT]: <SuggestedCards />,
    [resultsTypes.FREETEXT_RESULTS]: (
      <FreeTextResults data={data} isLoading={isLoading} noResults={type === resultsTypes.NO_RESULTS} query={query} />
    ),
    [resultsTypes.LOCATION_RESULTS]: <LocationResults data={data} onSelect={handleSelectLocation} />,
  };

  return componentByType[type] || null;
};

const SearchResults = () => {
  const { fields, currentFocusedField } = useSearch();
  const isLocation = currentFocusedField === fields.LOCATION;
  const isSimple = currentFocusedField === fields.SIMPLE_SEARCH;

  if (isSimple) {
    return (
      <SimpleContainer>
        <Results />
      </SimpleContainer>
    );
  }

  return (
    <Container $halfWidth={isLocation}>
      <Results />
    </Container>
  );
};

export default SearchResults;
