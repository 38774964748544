import React, { useContext, useEffect } from 'react';
import mixpanel from 'mixpanel-browser';
import IUser from '@lstv/core/types/IUser';
import { userEventsService } from '~/api/services/userEventService';
import { useAuthService } from '~/api/hooks/useAuthService';
import { isClient } from '~/utils/ssr';
import * as Sentry from '@sentry/nextjs';

/**
 * AK: should this file not go in utils/analytics or something like that?
 * IN: definitely - I think I'll move it in a separate refactor commit.
 */

export type EventType = string;

export interface EventPayload {
  /**
   * Sent only to GA, auto-populated.
   */
  event_category?: never;
  /**
   * Auto-populated.
   */
  page_url_path?: never;
  /**
   * Auto-populated.
   */
  user_logged_in?: never;
  /**
   * Auto-populated.
   */
  user_type?: never;
  /**
   * Auto-populated.
   */
  user_premium?: never;
  /**
   * Reserved for use by GA.
   */
  non_interaction?: never;
  [custom_key: string]: any;
}

export type AnalyticsTarget = 'lstv_be' | 'ga' | 'mixpanel' | 'klaviyo';

export interface TrackingOptions {
  includeTargets?: Iterable<AnalyticsTarget>;
  excludeTargets?: Iterable<AnalyticsTarget>;
}

// If this is required in the future, we can have this function return a Promise instead of `void`
// to notify that event has been sent.
interface EventHandler {
  (eventType: EventType, payload?: Record<string, any>, options?: TrackingOptions): void;
}

const deployedInProduction = isClient() && window.location.hostname === 'lovestoriestv.com';

const { postUserEvent } = userEventsService();

const handlerGa: EventHandler = (eventType, payload) => {
  const filteredPayload = Object.keys(payload)
    .map((key) => [key, payload[key]])
    .filter(([, value]) => typeof value === 'number' || typeof value === 'string' || typeof value === 'boolean')
    .reduce((acc, [key, value]) => ({ ...acc, [key]: value }), {});
  gtag('event', eventType, { non_interaction: true, event_category: 'v1', ...filteredPayload });

  const sendGaSpecificEvent = (name: string, label: string) => {
    gtag('event', name, {
      non_interaction: true,
      event_category: 'vendor_engagement',
      event_label: label,
    });
  };

  if (
    eventType === 'click_button' &&
    payload.button_action === 'Send' &&
    (payload.section === 'business_info_card' || payload.modal === 'contact_business')
  ) {
    sendGaSpecificEvent('ga_contact_vendor_form', payload.modal_slug ?? payload.page_slug);
  }

  if (eventType === 'click_link' && payload.section === 'business_info_card') {
    if (payload.subsection === 'contact_details') {
      if (payload.type === 'tel') {
        sendGaSpecificEvent('ga_contact_vendor_phone', payload.page_slug);
      }
      if (payload.type === 'external') {
        sendGaSpecificEvent('ga-contact-vendor-website', payload.page_slug);
      }
    }

    if (payload.subsection === 'social_links') {
      sendGaSpecificEvent('ga-contact-vendor-social', `${payload.page_slug} - ${payload.social_channel}`);
    }
  }
};

const handleMixpanel: EventHandler = (eventType, payload) => {
  mixpanel.track(eventType, payload);
};

const handleKlaviyo: EventHandler = (eventType, payload) => {
  // @ts-ignore
  (_learnq || []).push(['track', eventType, payload]);
};

// easier to do here than trying to shove the user into useTrackEvent
export const identifyToKlaviyo = (user: IUser) => {
  if (!user) return;
  // send if in production or if in config.sendTo
  const serializedOptions =
    typeof localStorage !== 'undefined' ? localStorage.getItem('analytics_event_tracking_config') : null;
  const config: Config = serializedOptions ? JSON.parse(serializedOptions) : {};
  if (deployedInProduction || config.sendTo?.includes('klaviyo')) {
    const payload = {
      $email: user.email,
      $first_name: user.firstName,
      $last_name: user.lastName,
      user_type: user.userType,
    };
    // @ts-ignore
    (_learnq || []).push(['identify', payload]);
  }
};

const handleBackend: EventHandler = (eventType, payload) => {
  postUserEvent({
    domain: 'user_action',
    event: eventType,
    severity: 'info',
    data: payload,
  });
};

const handlers: { [key in AnalyticsTarget]: EventHandler } = {
  ga: handlerGa,
  mixpanel: handleMixpanel,
  klaviyo: handleKlaviyo,
  lstv_be: handleBackend,
};

const useCommonParams = () => {
  const { loggedIn, user } = useAuthService();
  if (isClient()) {
    const page_url_path = window.location.pathname;

    return {
      page_url_path,
      user_logged_in: loggedIn,
      ...(loggedIn
        ? {
            user_type: user.userType === 'business_team_member' ? 'business' : 'consumer',
            user_premium: user.subscriptionLevel !== 'free',
          }
        : {}),
    };
  }
};

/**
 * Configuration stored in localStorage. Used for development and testing, ignored when deployed to production.
 */
interface Config {
  /**
   * Whether to log events to the console. Default: `false`.
   */
  log?: boolean;
  /**
   * Platforms where to send events. Default: `['lstv_be']`.
   */
  sendTo?: string[];
}

const log = console.log;

const trackEvent = (eventType: EventType, payload?: Record<string, any>, options?: TrackingOptions) => {
  try {
    const config: Config | undefined = (() => {
      if (!deployedInProduction) {
        const serializedOptions =
          typeof localStorage !== 'undefined' ? localStorage.getItem('analytics_event_tracking_config') : null;
        if (serializedOptions !== null) {
          return JSON.parse(serializedOptions);
        }
      }
    })();
    if (config?.log) {
      log(`${eventType} event with parameters ${JSON.stringify(payload, null, '  ')}`, ...(options ? [options] : []));
    }
    const targetsBeforeExcluded = [...(options?.includeTargets ?? (Object.keys(handlers) as AnalyticsTarget[]))];
    const excludeTargetsSet = new Set(options?.excludeTargets ?? []);
    targetsBeforeExcluded
      .filter(
        (target) =>
          !excludeTargetsSet.has(target) &&
          (deployedInProduction || (config?.sendTo ?? ['lstv_be']).some((value) => value === target))
      )
      .forEach((target) => handlers[target](eventType, payload, options));
  } catch (error) {
    console.error('Error sending analytics event', error);
    Sentry.captureException(error);
  }
};
export const trackServerEvent = trackEvent;

/**
 * Exported as a fallback, normally you would instead use EventParams and useTrackEvent.
 */
export const EventContext = React.createContext<{ payload: EventPayload }>({ payload: {} });

/**
 * Adds analytics event parameters to the UI context. You supply these parameters as props.
 * When a descendant component emits an event via useTrackEvent, these parameters
 * will be added to the event payload. If the name of a parameter matches one already set
 * by an ancestor, this parameter will be overriden.
 */
export const EventParams = ({ children, ...payload }: EventPayload & { children: React.ReactNode }) => {
  const outerPayload = useContext(EventContext).payload;
  return <EventContext.Provider value={{ payload: { ...outerPayload, ...payload } }}>{children}</EventContext.Provider>;
};

/**
 * Returns a function that sends an event to analytics platforms. By default the event is sent to
 * all platforms, but platforms can be whitelisted or blacklisted via options.
 *
 * Notes specific to GA:
 *
 * - Currently we always send `non_interaction = true` to GA. If necessary, an
 *   option to control this behavior can be added to `TrackingOptions` in the future.
 *
 * - Only parameter values of types number/string/boolean are sent to GA.
 *
 * - We send an additinal parameter specifically to GA: event_category='v1' (since it's required).
 *
 * When adding/modifying events, pls update the spreadsheet here:
 * https://docs.google.com/spreadsheets/d/1_ZVsPFLFV2GJHZm8tYP77rZfXwvDJUSnls9mZijhvwk/edit?usp=sharing
 */
export const useTrackEvent = () => {
  const commonParams = useCommonParams();
  const outerPayload = useContext(EventContext).payload;
  return (eventType: EventType, payload?: EventPayload, options?: TrackingOptions) =>
    trackEvent(eventType, { ...commonParams, ...outerPayload, ...payload }, options);
};

/**
 * Sends an 'impression' analytics event after initial render.
 */
export const TrackImpression = ({ children }: { children: React.ReactNode }) => {
  const trackEvent = useTrackEvent();
  useEffect(() => {
    trackEvent('impression');
  }, [trackEvent]);
  return children;
};
