import axios from 'axios';
import { useEffect } from 'react';
import { destroyCookie, setCookie } from 'nookies';

// import signOut from 'authService';

/**
 * Will work once all axios calls operate on global instance.
 */
const ApiErrorBoundary = () => {
  useEffect(() => {
    axios.interceptors.response.use((response) => response, responseErrorInterceptor);
  }, []);
  return null;
};

/**
 * Use this for individual axios instances
 */
export const responseErrorInterceptor = (error) => {
 
  if (error?.response?.status === 401) {
    console.warn('ApiErroBoundary - err', error);
    if (!window.location.href.endsWith('/sign-in')) {
          destroyCookie(null, 'lstv.client.user');
          alert('Your session has expired. click OK to sign in again.');
          window.location.href='/sign-in';
      }
  }
  return Promise.reject(error);
};

export default ApiErrorBoundary;
