import Modal from '../Modal';
import { EventParams } from '~/globals/trackEvent';
import { useModals } from '~/hooks/useModals';
import SocialMediaButtonsBar from './SocialMediaButtonsBar';
import UrlShareBar from './UrlShareBar';

const ShareModal = () => {
  const { isShareModalOpen, closeShareModal, shareModalParams } = useModals();

  if (!isShareModalOpen) return null;

  return (
    <EventParams modal="share">
      <Modal
        open
        data-scroll-lock-scrollable
        height="fit-content"
        width="95vw"
        onClose={closeShareModal}
        title={shareModalParams?.title}
        customStyles={{
          content: {
            overflow: 'unset',
            maxWidth: '650px',
            margin: 'auto',
          },
          container: {},
        }}
      >
        <UrlShareBar urlToShare={shareModalParams.url} />
        <SocialMediaButtonsBar urlToShare={shareModalParams.url} thumbnailUrl={shareModalParams.thumbnailUrl} />
      </Modal>
    </EventParams>
  );
};

export default ShareModal;
