import styled from 'styled-components';
import { BookmarkButton } from '../BookmarkButton';

export const ThumbnailContainer = styled.div`
  position: relative;
  border-radius: 5px;
  overflow: hidden;
  width: 100%;

  &:before {
    content: '';
    display: block;
    padding-bottom: 56.25%;
  }

  & > img {
    position: absolute;
    top: 0;
  }

  & > .overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    color: ${(props) => props.theme.white};
    background: linear-gradient(180deg, rgba(0, 0, 0, 0) 68.09%, rgba(0, 0, 0, 0.8) 100%);
    text-decoration: none;
  }

  &:not(:hover) .hover {
    display: none;
  }

  &:hover .no-hover {
    display: none;
  }
`;

export const StyledBookmarkButton = styled(BookmarkButton)`
  position: absolute;
  right: 0;
  top: 0;
  width: 56px;
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: transparent;
  cursor: pointer;
  filter: drop-shadow(0px 0px 4px rgba(0, 0, 0, 0.25));
`;

export const WfaBadge = styled.img`
  position: absolute;
  left: 14px;
  top: 14px;
  width: 14.5%;
  background-color: ${(props) => props.theme.highlight_1_0};
  border-radius: 1000px;
`;

export const PlayIconContainer = styled.div`
  flex: 0 0 auto;
  width: 48px;
  height: 54px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-left: 2px;
  filter: drop-shadow(0px 0px 4px rgba(0, 0, 0, 0.25));
`;

export const DurationContainer = styled.div`
  font-size: 15px;
  width: 90px;
  flex: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 20px;
  text-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
`;

export const CaptionContainer = styled.div`
  font-size: 15px;
  color: ${({ theme }) => theme.coldGrey};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-top: 7px;
  margin-bottom: 3px;
`;

export const VideographerNameContainer = styled.div`
  font-size: 19px;
  line-height: 24px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-left: 9px;
`;

export const VibeContainer = styled.div`
  display: flex;
  align-items: center;
  font-size: 15px;
  border: 1px solid ${(props) => props.theme.blueGrey};
  height: 26px;
  border-radius: 26px;
  padding: 0 7px;

  & + & {
    margin-left: 9px;
  }
`;
