import { ParsedUrlQuery } from 'querystring';

/**
 * getServerSideProps helper fn for safely getting url query or params.
 * @param query A ParsedUrlQuery - either ctx.params or ctx.query
 * @param key String key of the param you're after
 */
export const getQueryParam = (query: ParsedUrlQuery, key: string) => {
  if (!query || !key) return null;

  const param = query[key];
  if (!param) return null;

  if (Array.isArray(param)) {
    return param[0];
  }
  return param;
};
