import styled from 'styled-components';
import theme from '~/styles/styledComponentsTheme';
import { SearchIcon } from '~/components/InlineSVG';
import Typography from '~/atoms/Typography';

const Container = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  overflow-x: hidden;

  @media ${theme.breakpoints.laptop} {
    padding-top: 32px;
    padding-bottom: 32px;
  }
`;

const Subtitle = styled.p`
  font-family: Calibre;
  font-weight: 400;
`;

const IconContainer = styled.div`
  height: 50px;
  width: 50px;
`;

const NoResultsBirds = styled.img`
  margin: 32px 0 0 0;

  @media ${theme.breakpoints.laptop} {
    display: none;
  }
`;

const NoResults = ({ hideBirds }: { hideBirds?: boolean }) => {
  return (
    <Container>
      <IconContainer>
        <SearchIcon fill={theme.highlight_7_0} storkeWidth="3" />
      </IconContainer>
      <Typography variant="h1" fontWeight="800">
        No Results
      </Typography>
      <Subtitle>
        We couldn&apos;t find anything for your search term
        <br />
      </Subtitle>
      {!hideBirds && <NoResultsBirds src="/images/search-birds.svg" />}
    </Container>
  );
};

export default NoResults;
