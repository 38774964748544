import React from 'react';
import styled from 'styled-components';
import classNames from 'classnames';
import { useField } from 'formik';

const Container = styled.label`
  display: flex;
  align-items: center;

  &.disabled {
    opacity: 0.2;
  }

  &:not(.disabled) {
    cursor: pointer;

    input {
      cursor: pointer;
    }
  }

  .background {
    flex: 0 0 auto;
    width: 23px;
    height: 23px;
    background-color: ${(props) => props.theme.white};
    border-radius: 5px;
    position: relative;
  }

  &.checked .background {
    background-color: ${(props) => props.theme.highlight_7_0};
  }

  &:not(.checked) .background {
    border: 1px solid ${(props) => props.theme.darkGrey};
  }

  input {
    position: absolute;
    opacity: 0;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  svg {
    position: absolute;
    top: 1px;
    left: 1px;
  }

  &:hover:not(.disabled).checked .background:before {
    content: '';
    position: absolute;
    top: -2px;
    left: -2px;
    bottom: -2px;
    right: -2px;
    border: 1px solid ${(props) => props.theme.lightHighlightColor};
    border-radius: 7px;
  }

  &:hover:not(.disabled):not(.checked) .background {
    border-color: ${(props) => props.theme.highlight_7_0};
  }

  .caption {
    flex: 1 1 0;
    margin-left: 18px;
    text-align: left;
    font-weight: 500;
    font-size: 18px;
  }

  .nwl-caption {
    margin-left: 10px;
    font-size: 18px;
  }
`;

const checkIcon = (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M19.3006 3.2412C19.9146 3.81218 19.9495 4.7728 19.3785 5.3868L8.43787 17.1515C8.15065 17.4604 7.7479 17.6358 7.32614 17.6358C6.90437 17.6358 6.50162 17.4604 6.2144 17.1515L1.2414 11.8039C0.670411 11.1899 0.705276 10.2293 1.31927 9.65831C1.93327 9.08732 2.89388 9.12219 3.46487 9.73619L7.32614 13.8883L17.155 3.31907C17.726 2.70508 18.6866 2.67021 19.3006 3.2412Z"
      fill="white"
    />
  </svg>
);

/**
 * All props except label, className and style are passed down to the input
 * element. className and style are applied to the outer element.
 */
export interface CheckboxProps extends React.InputHTMLAttributes<HTMLInputElement> {
  label?: string;
  normalWeightLabel?: boolean;
}

/**
 * When a label is provided, checkbox + label combo, if not provided, just a
 * checkbox w/o any padding or margins around it.
 */
export const CheckBox = ({ label, className, style, normalWeightLabel, ...rest }: CheckboxProps) => (
  <Container className={classNames(className, { disabled: rest.disabled, checked: rest.checked })} {...{ style }}>
    <div className="background">
      {rest.checked && checkIcon}
      <input type="checkbox" {...rest} />
    </div>
    {label && <span className={normalWeightLabel ? 'nwl-caption' : 'caption'}>{label}</span>}
  </Container>
);

export const FormikCheckBox = ({
  label,
  className,
  onChange,
  onBlur,
  disabled,
  name,
  defaultChecked,
  normalWeightLabel,
}: {
  label?: string;
  className?: string;
  onChange?: React.ChangeEventHandler<HTMLInputElement>;
  onBlur?: React.FocusEventHandler<HTMLInputElement>;
  disabled?: boolean;
  name?: string;
  defaultChecked?: boolean;
  normalWeightLabel?: boolean;
}) => {
  const [{ checked, onChange: onChangeFormik, onBlur: onBlurFormik }] = useField({
    type: 'checkbox',
    name,
  });
  return (
    <CheckBox
      {...{
        checked,
        name,
        onChange: (event) => {
          onChange?.(event);
          onChangeFormik?.(event);
        },
        onBlur: (event) => {
          onBlur?.(event);
          onBlurFormik?.(event);
        },
        label,
        className,
        disabled,
        defaultChecked,
        normalWeightLabel,
      }}
    />
  );
};
