import { faTimes } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box, Dialog } from '@material-ui/core';
import React, { CSSProperties } from 'react';
import styled from 'styled-components';
import Typography from '~/atoms/Typography';

const Header = styled.div`
  margin-bottom: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

interface Props {
  open: boolean;
  title?: string;
  onClose: () => void;
  width: number;
  height: number;
  style?: CSSProperties;
}
const ElegantModal: React.FC<Props> = ({ open, title, width, height, onClose, children, style }) => {
  return (
    <Dialog open={open} onClose={onClose} maxWidth="lg" style={style}>
      <Box style={{ width: '100%', maxWidth: width, height, padding: 20 }}>
        <Header>
          <Typography variant="h2">{title ?? ''}</Typography>
          <FontAwesomeIcon icon={faTimes} size="2x" onClick={onClose} style={{ cursor: 'pointer' }} />
        </Header>
        {children}
      </Box>
    </Dialog>
  );
};

export default ElegantModal;
