import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';
// import { useLocation, useHistory } from 'react-router';

function useQuery() {
  const [query, setQuery] = useState<URLSearchParams>();
  useEffect(() => {
    setQuery(new URLSearchParams(window.location.search));
  }, []);
  return query;
}

/**
 * TODO: This may need work migrating from react-router to next/router
 */
const useQueryParams = (): [string, string, (term: string, loc: string) => void] => {
  const query = useQuery();
  // const RouterLocation = useLocation();
  // const history = window.history
  const router = useRouter();

  const [searchTermState, setSearchTermState] = useState(query?.get('q'));
  const [locationState, setLocationState] = useState(query?.get('loc'));

  useEffect(() => {
    // Fired when url changes
    setLocationState(query?.get('loc'));
    setSearchTermState(query?.get('q'));
  }, [router, query]);

  const setParams = (searchTerm, location) => {
    if (location && searchTerm) {
      // TODO: fix these obvsiously
      // history.replace({...RouterLocation, search: `?q=${searchTerm}&loc=${encodeURI(location)}`});
      setSearchTermState(searchTerm);
      setLocationState(location);
    } else if (location && !searchTerm) {
      // history.replace({...RouterLocation, search: `?q=${''}&loc=${encodeURI(location)}`});
      setLocationState(location);
    } else if (searchTerm) {
      // history.replace({...RouterLocation, search: `?q=${searchTerm}&loc=${''}`});
      setSearchTermState(searchTerm);
    } else {
      // history.replace({ ...RouterLocation, search: `` });
      setSearchTermState('');
      setLocationState('');
    }
  };

  return [searchTermState, locationState, setParams];
};

export default useQueryParams;
