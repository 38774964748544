import styled from 'styled-components';
import Image from 'next/image';

const UserInitialImage = styled.div<{ $size: string; $fontSize: string; $minSize?: string; $maxSize?: string }>`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: ${(props) => props.theme.highlight_7_0};
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;

  p {
    font-size: ${(props) => props.$fontSize || '1.125rem'};
    line-height: 1.125rem;
    font-weight: 400;
    margin-bottom: 0.1rem;
  }
`;

const UserAvatarImage = styled.img`
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  object-fit: cover;
`;

const ImageWrapper = styled.div<{ $size: string; $minSize?: string; $maxSize?: string }>`
  width: ${(props) => props.$size || '32px'};
  ${(props) => (props.$minSize ? `min-width: ${props.$minSize}; min-height: ${props.$minSize};` : '')}
  ${(props) => (props.$maxSize ? `max-width: ${props.$maxSize}; max-height: ${props.$maxSize};` : '')}
  border-radius: 1000px;
  overflow: hidden;
  display: grid;
  position: relative;

  & > * {
    grid-area: 1/1;
  }
`;

const Avatar = ({
  imageSrc,
  initial,
  /**
   * If in %, this is relative to the container's horizontal size.
   */
  size = '42px',
  fontSize = '1.125rem',
  minSize = undefined,
  maxSize = undefined,
  ...rest
}) => {
  return (
    <ImageWrapper $size={size} $minSize={minSize} $maxSize={maxSize} {...rest}>
      {/* The svg is used to create 1:1 aspect ratio. Instead of the padding trick, which doesn't support 
      max-height, we use grid layout: https://stackoverflow.com/a/53245657/7612287 In a year or so, aspect-ratio 
      css rule would be supported on all iPhones and will be a better option. */}
      <svg viewBox="0 0 1 1" />
      {imageSrc ? (
        <UserAvatarImage src={imageSrc} />
      //   <Image
      //     key={imageSrc}
      //     src={imageSrc}
      //     alt={initial}
      //     layout="fill"
      //     objectFit="cover"
      //     priority={true}
      // ></Image>
      ) : (
        <UserInitialImage $size={size} $minSize={minSize} $maxSize={maxSize} $fontSize={fontSize}>
          <p>{initial}</p>
        </UserInitialImage>
      )}
    </ImageWrapper>
  );
};

export default Avatar;
