import React from 'react';
import dynamic from 'next/dynamic';
import { useRouter } from 'next/router';
import { FC } from 'react';
import { BaseHead } from '~/components/Head';
import Header from '~/components/HeaderDefault';
import ContactBusinessModal from '~/components/modals/ContactBusinessModal';
import FlagModal from '~/components/modals/FlagModal';
import LoginModal from '~/components/modals/LoginModal';
import ShareModal from '~/components/modals/ShareModal';
import { ModalsProvider } from '~/hooks/useModals';
import { DirectoryProvider } from '~/hooks/useDirectories';
import Navigator from '~/lib/Navigator';
import * as S from './DefaultLayout.styles';
import { isStaging, isProduction } from '~/utils/next';
import NextHead from 'next/head';
import { NavBarProvider } from '~/hooks/useNavBar';

const Footer = dynamic(() => import('~/components/Footer'), { ssr: false });

const noFooterRoutes = [
  Navigator.signIn,
  Navigator.signUp,
  Navigator.forgotPassword,
  Navigator.setNewPassword,
  Navigator.dashboard.videos,
];

/**
 * NavLayout might be a better name than DefaultLayout? It's used everywhere except embeds.
 */
const Layout: FC = ({ children }) => {
  const router = useRouter();
  const hideFooter = noFooterRoutes.includes(router.pathname);
  const intercomAppId = 'ffaqxywa';

  return (
    <>
      <BaseHead />

      <NextHead>
        {(isStaging() || isProduction()) && (
          <script
            dangerouslySetInnerHTML={{
              __html: `
                window.intercomSettings = {
                  app_id: '${intercomAppId}',
                  alignment: 'right',
                };
                (function(){var w=window;var ic=w.Intercom;if(typeof ic==="function"){ic('reattach_activator');ic('update',w.intercomSettings);}else{var d=document;var i=function(){i.c(arguments);};i.q=[];i.c=function(args){i.q.push(args);};w.Intercom=i;var l=function(){var s=d.createElement('script');s.type='text/javascript';s.async=true;s.src='https://widget.intercom.io/widget/ffaqxywa';var x=d.getElementsByTagName('script')[0];x.parentNode.insertBefore(s,x);};if(w.attachEvent){w.attachEvent('onload',l);}else{w.addEventListener('load',l,false);}}})();
              `,
            }}
          />
        )}

        <script
          dangerouslySetInnerHTML={{
            __html: isProduction()
              ? `
                  window.Userback = window.Userback || {};
                  Userback.access_token = '11107|23731|WOfZfvFnhteNXzg1gP4ewwKlT';
                  (function (d) {
                      var s = d.createElement('script');
                      s.async = true;
                      s.src = 'https://static.userback.io/widget/v1.js';
                      (d.head || d.body).appendChild(s);
                  })(document);
                `
              : `
                  Userback = window.Userback || {};
                  Userback.access_token = '11107|22984|WOfZfvFnhteNXzg1gP4ewwKlT';
                  (function (id) {
                      var s = document.createElement('script');
                      s.async = 1;
                      s.src = 'https://static.userback.io/widget/v1.js';
                      var parent_node = document.head || document.body;
                      parent_node.appendChild(s);
                  })('userback-sdk');
                `,
          }}
        />
      </NextHead>

      <ModalsProvider>
        <DirectoryProvider>
          <NavBarProvider>
            <Header />

            <S.ContentContainer>{children}</S.ContentContainer>

            {/*
              A little confusing. This is the global footer component
              with all the site links and socials. It is responsive and
              renders both mobile and desktop sizes.
            */}
            {!hideFooter && <Footer />}

            {/* TODO: render these only on pages where they need to be if possible */}
            <FlagModal />
            <ContactBusinessModal />
            <LoginModal />
            <ShareModal />
          </NavBarProvider>
        </DirectoryProvider>
      </ModalsProvider>
    </>
  );
};

export default Layout;
