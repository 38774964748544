import styled from 'styled-components';
import ContactBusinessForm from '~/components/forms/ContactBusinessForm';
import { VendorHeaderFromBusiness } from '~/components/VendorHeader';
import { useModals } from '~/hooks/useModals';
import theme from '~/styles/styledComponentsTheme';
import { EventParams } from '~/globals/trackEvent';
import ElegantModal from './ElegantModal';

const FormContainer = styled.div`
  margin: 10px auto 20px;
  max-width: 420px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  z-index: 10;

  @media ${theme.breakpoints.laptop} {
    max-width: initial;
  }
`;

const FormHeader = styled.div`
  @media ${theme.breakpoints.laptop} {
    width: auto;
    align-self: flex-start;
  }
`;

const ContactBusinessModal = () => {
  const { isContactBusinessModalOpen, closeContactBusinessModal, contactBusinessModalParams } = useModals();
  const { business, videoId, message } = contactBusinessModalParams || {};

  if (!isContactBusinessModalOpen) return null;

  const width = Math.min(600, document.body.clientWidth - 60);
  const height = Math.min(800, document.body.clientHeight - 40);

  return (
    <EventParams modal="contact_business" modal_slug={business.slug}>
      <ElegantModal open={isContactBusinessModalOpen} onClose={closeContactBusinessModal} width={width} height={height}>
        <FormContainer>
          {business && (
            <FormHeader style={{ marginBottom: 20 }}>
              <VendorHeaderFromBusiness business={business} />
            </FormHeader>
          )}
          <ContactBusinessForm
            business={business}
            videoId={videoId}
            message={message}
            onSuccess={() => closeContactBusinessModal()}
            onClose={() => closeContactBusinessModal()}
          />
        </FormContainer>
      </ElegantModal>
    </EventParams>
  );
};

export default ContactBusinessModal;
