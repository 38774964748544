import React from 'react';
import styled from 'styled-components';
import { faCheckCircle } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Chip } from '@material-ui/core';
import Typography from '~/atoms/Typography';

const Container = styled.div`
  display: flex;
`;

export const ThumbnailContainer = styled.div`
  height: 114px;
  width: 114px;
  margin: auto;
`;

export const Thumbnail = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 50%;
`;

const Brand = styled.div<{ $hasImage: boolean }>`
  flex: 1;
  margin: ${(props) => (props.$hasImage ? '0 0 0 10px' : 'none')};
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const TopTitle = styled.div`
  display: flex;
  align-items: center;
  font-size: 1.25rem;
`;

const Location = styled.p`
  font-size: 1.125em;
  font-weight: 600;
`;

interface Props {
  name: string;
  location: string;
  className?: string;
  avatarImageUrl?: string;
  roles?: string[];
  hide_location?: boolean;
  suggested?: boolean;
}
export const VendorHeader = ({ name, location, className, avatarImageUrl, roles, hide_location, suggested }: Props) => (
  <Container {...{ className }}>
    {avatarImageUrl && (
      <ThumbnailContainer>
        <Thumbnail src={avatarImageUrl} />
      </ThumbnailContainer>
    )}
    <Brand $hasImage={!!avatarImageUrl}>
      <TopTitle>
        {roles?.map((r) => (
          <Chip key={r} label={r} size="small" style={{ marginRight: 10 }} variant="outlined" />
        ))}
        {suggested && (
          <span style={{ display: 'inline-flex', alignItems: 'center' }}>
            <FontAwesomeIcon icon={faCheckCircle} style={{ marginRight: 5 }} />
            Suggested
          </span>
        )}
      </TopTitle>
      <Typography variant="h1" margin="4px 0 2px" padding="0" fontWeight="800">
        {name}
      </Typography>
      {!hide_location && <Location>{location}</Location>}
    </Brand>
  </Container>
);

export const VendorHeaderFromBusiness = ({ business, className }: { business: any; className?: string }) => (
  <VendorHeader
    {...{ className }}
    name={business.name}
    location={business.location?.display_name}
    avatarImageUrl={business.profileImageUrl || business.thumbnailUrl || business.profile_image}
    roles={business.roles?.map(({ name }) => name)}
    hide_location={business.hide_location}
    suggested={business.subscription_level !== 'free'}
  />
);
