import classNames from 'classnames';
import { Form, Formik } from 'formik';
import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import * as Yup from 'yup';
import { IBusiness } from '@lstv/core/types';
import { useAuthService } from '~/api/hooks/useAuthService';
import { useBusinessService } from '~/api/hooks/useBusinessService';
import { ErrorMessage, FormRow, Input, InputWrapper, TextArea } from '~/components/forms/StyledForm';
import theme from '~/styles/styledComponentsTheme';
import { popMessageSuccess } from '~/utils/LSTVUtils';
import { CheckBox, FormikCheckBox } from '../buttons/BaseCheckBox';
import { CtaButton, CtaColorScheme, CtaSize } from '../cta/regular';
import Avatar from '../Avatar';
import { VibeContainer } from '../cards/VideoCard/index.styles';

const SubmitFormRow = styled(FormRow)<{ $compact?: boolean }>`
  margin: 0;
`;

const SimilarBusinessContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 10px 5px;
  margin-right: 10px;

  width: 100px;
  height: 136px;
  border: 2px solid ${theme.blueGrey};
  border-radius: 4px;
`;

interface SimilarBusinessProps {
  business: IBusiness;
  checked: boolean;
  setChecked: (checked: boolean) => void;
}
const SimilarBusiness: React.FC<SimilarBusinessProps> = ({ business, checked, setChecked }) => {
  return (
    <SimilarBusinessContainer>
      <div style={{ position: 'relative', paddingTop: 28, width: '100%' }}>
        <CheckBox
          style={{ position: 'absolute', right: 0, top: -3 }}
          checked={checked}
          onChange={(e) => setChecked(e.target.checked)}
        />
      </div>
      <Avatar imageSrc={business.profile_image} initial={business.name[0]} size="35px" />
      <div style={{ textOverflow: 'ellipsis', overflow: 'hidden', whiteSpace: 'nowrap', width: '100%' }}>
        {business.name}
      </div>
      <VibeContainer style={{ marginTop: 6 }}>{business.roles[0].name}</VibeContainer>
    </SimilarBusinessContainer>
  );
};

const ContactBusinessForm = ({
  business,
  message,
  videoId,
  onSuccess,
  onClose,
  compact,
  className,
}: {
  business;
  message?: string;
  videoId?: string;
  onSuccess?: () => void;
  onClose?: () => void;
  compact?: boolean;
  className?: string;
}) => {
  const { user, loggedIn } = useAuthService();
  const { contactBusiness, getSimilarBusinesses } = useBusinessService();
  const [submitError, setSubmitError] = useState('');
  const [similarBusinesses, setSimilarBusinesses] = useState<IBusiness[] | undefined>();
  const [selectedSimilarBusinesses, setSelectedSimilarBusinesses] = useState<string[]>([]);
  const similarBusinessDiv = useRef<HTMLDivElement>(undefined);

  const nameFieldName = 'contact-business-name';
  const emailFieldName = 'contact-business-email';
  const messageFieldName = 'contact-business-message';
  const optInFieldName = 'contact-business-optin';

  const schema = Yup.object().shape({
    [nameFieldName]: Yup.string().required('A name is required'),
    [emailFieldName]: Yup.string().email('Must be a valid email address').required('An email address is required'),
    [messageFieldName]: Yup.string(),
    [optInFieldName]: Yup.boolean(),
  });

  const initialValues = {
    [emailFieldName]: loggedIn ? user.email : '',
    [nameFieldName]: loggedIn ? `${user.firstName} ${user.lastName}` : '',
    [messageFieldName]:
      message || (typeof window !== 'undefined' ? window.sessionStorage.getItem('contactMessage') ?? '' : ''),
    [optInFieldName]: true,
  };

  const submit = async (values, helpers) => {
    const { setSubmitting, resetForm } = helpers;

    try {
      let businessRole = 'videographer';
      // business object may take different shape
      // depending on the page it came from :-/
      if (business.roles?.length) {
        businessRole = business.roles[0].slug;
      } else if (business.role_slug) {
        businessRole = business.role_slug;
      }

      // TSFIXME
      const params: any = {
        fromPage: window.location.pathname,
        guestName: values[nameFieldName],
        guestEmail: values[emailFieldName],
        message: values[messageFieldName],
        optIn: values[optInFieldName],
        businessName: business.name,
        businessRole,
        businessSlug: business.slug,
        videoId: videoId || undefined,
        userUid: user?.uid,
        chosenSimilarBusinesses: selectedSimilarBusinesses.join(','),
      };

      await contactBusiness(params);
      resetForm();
      setSubmitting(false);
      popMessageSuccess('Submitted Successfully');
      onSuccess && onSuccess();
    } catch (error) {
      setSubmitting(false);
      setSubmitError('Sorry, we had an error. please try again later.');
    }
  };

  useEffect(() => {
    async function go() {
      const width = Math.min(600, similarBusinessDiv.current.clientWidth);
      const size = Math.floor(width / 100);
      const sims = await getSimilarBusinesses(business.slug, size);
      if (sims.success) setSimilarBusinesses(sims.businesses);
    }
    go();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [business.slug]);

  return (
    <Formik validateOnMount initialValues={initialValues} onSubmit={submit} validationSchema={schema}>
      {({ values, touched, errors, isValid, isSubmitting, handleChange, handleSubmit, handleBlur }) => {
        const handleMessageChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
          window.sessionStorage.setItem('contactMessage', e.target.value);
          handleChange(e);
        };
        return (
          <Form className={classNames(className, { 'responsive-layout': !compact })} onSubmit={handleSubmit}>
            <FormRow>
              <InputWrapper $compact={true}>
                <Input
                  name={nameFieldName}
                  value={values[nameFieldName]}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  placeholder="Your Name"
                  type="text"
                  style={{ background: theme.grey_5_0, color: theme.grey_1_0 }}
                />
                {errors[nameFieldName] && touched[nameFieldName] ? (
                  <ErrorMessage>{errors[nameFieldName]}</ErrorMessage>
                ) : null}
              </InputWrapper>
            </FormRow>

            <FormRow>
              <InputWrapper $compact={true}>
                <Input
                  name={emailFieldName}
                  value={values[emailFieldName]}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  placeholder="Your Email"
                  form="novalidatedform"
                  type="email"
                  style={{ background: theme.grey_5_0, color: theme.grey_1_0 }}
                />
                {errors[emailFieldName] && touched[emailFieldName] ? (
                  <ErrorMessage>{errors[emailFieldName]}</ErrorMessage>
                ) : null}
              </InputWrapper>
            </FormRow>

            <FormRow>
              <InputWrapper $compact={true}>
                <TextArea
                  name={messageFieldName}
                  placeholder="Let the vendor know what you're looking for along with any wedding details such as location or date so they can help you."
                  value={values[messageFieldName]}
                  onChange={handleMessageChange}
                  onBlur={handleBlur}
                  style={{ background: theme.grey_5_0, color: theme.grey_1_0 }}
                />
              </InputWrapper>
            </FormRow>

            {(similarBusinesses === undefined || similarBusinesses.length > 0) && (
              <>
                <FormRow>
                  <div style={{ color: theme.grey_1_0, marginLeft: 5, marginBottom: 5 }}>
                    Send inquiry to any additional wedding teams?
                  </div>
                </FormRow>

                <FormRow>
                  <InputWrapper $compact={true}>
                    <div style={{ display: 'flex' }} ref={similarBusinessDiv}>
                      {similarBusinesses?.map((business) => (
                        <SimilarBusiness
                          key={business.slug}
                          business={business}
                          checked={selectedSimilarBusinesses.includes(business.slug)}
                          setChecked={(checked) =>
                            setSelectedSimilarBusinesses((slugs) =>
                              checked
                                ? Array.from(new Set([...slugs, business.slug]))
                                : slugs.filter((s) => s != business.slug)
                            )
                          }
                        />
                      ))}
                    </div>
                  </InputWrapper>
                </FormRow>
              </>
            )}

            {!loggedIn && (
              <FormRow className="checkbox-form-row">
                <InputWrapper $compact={true}>
                  <FormikCheckBox
                    defaultChecked={true}
                    name={optInFieldName}
                    label={`Get more wedding vendor recommendations from Love Stories TV`}
                    normalWeightLabel={true}
                  />
                </InputWrapper>
              </FormRow>
            )}

            <ErrorMessage>{submitError || ''}</ErrorMessage>
            <SubmitFormRow $compact={true}>
              <InputWrapper $compact={true} style={{ textAlign: 'right' }}>

                {typeof window !== 'undefined' && !window.location.pathname.includes('/business/') && (

                  <CtaButton
                    $colorScheme={CtaColorScheme.Plain}
                    style={{ background: theme.grey_2_0, marginRight: 10 }}
                    $size={CtaSize.Small}
                    caption="Cancel"
                    onClick={() => onClose && onClose()}
                  />
                )}

                {business.isClosed || business.is_closed ? (
                  <CtaButton
                    $colorScheme={CtaColorScheme.Highlight}
                    $size={CtaSize.Small}
                    type="submit"
                    caption="Business is Closed"
                    eventAction="Send"
                    disabled={true}
                  />
                ) : (
                  <CtaButton
                    $colorScheme={CtaColorScheme.Highlight}
                    $size={CtaSize.Small}
                    type="submit"
                    caption="Send"
                    eventAction="Send"
                    disabled={!isValid || isSubmitting}
                  />
                )}
              </InputWrapper>
            </SubmitFormRow>
          </Form>
        );
      }}
    </Formik>
  );
};

export default ContactBusinessForm;
