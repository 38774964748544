import React, { useState, createContext, useContext } from 'react';
import useMedia from 'use-media';
import theme from '~/styles/styledComponentsTheme';

export type NavBarModal = 'none' | 'vendors' | 'more';
interface INavBarContext {
  modal: NavBarModal;
  setModal: React.Dispatch<React.SetStateAction<NavBarModal>>;
}
const NavBarContext = createContext<INavBarContext>({ modal: 'none', setModal: () => {} });

const useNavBar = () => {
  return useContext(NavBarContext);
};

const useProvideNavBar = () => {
  const [modal, setModal] = useState<NavBarModal>('none');
  const isMobile = useMedia(theme.breakpoints.isMobileOrTablet);

  const keyDownListener = (e: KeyboardEvent) => {
    if (e.key === 'Escape') setModal('none');
  };
  const scrollListener = () => {
    if (!isMobile) setModal('none');
  };
  React.useEffect(() => {
    document.addEventListener('keydown', keyDownListener);
    document.addEventListener('scroll', scrollListener);
    return () => {
      document.removeEventListener('keydown', keyDownListener);
      document.removeEventListener('scroll', scrollListener);
    };
  });

  return {
    modal,
    setModal,
  };
};

function NavBarProvider({ children }) {
  const search = useProvideNavBar();
  return <NavBarContext.Provider value={search}>{children}</NavBarContext.Provider>;
}

export { useNavBar, NavBarProvider };
