import React from 'react';
import { faBookmark } from '@fortawesome/pro-regular-svg-icons';
import { faBookmark as faBookmarkSolid } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useState } from 'react';
import { useAuthService } from '~/api/hooks/useAuthService';
import PublicContentService from '~/api/services/publicContentService';
import { useModals } from '~/hooks/useModals';
import LSTVButton from '../LSTVButton';
import { popMessageSuccess } from '~/utils/LSTVUtils';

export const BookmarkButton = ({
  type,
  slug,
  className,
}: {
  type: 'video' | 'business';
  slug: string;
  className?: string;
}) => {
  const { loggedIn, bookmarks, setBookmarks } = useAuthService();
  // Used to temporarily store the result of an optimistic update while the HTTP request is in progress.
  const [optimisticBookmarked, setOptimisticBookmarked] = useState<boolean | undefined>(undefined);
  const { openLoginModal } = useModals();

  const bookmarked = React.useMemo(() => {
    if (!bookmarks || !slug) return false;
    if (type === 'business') return bookmarks.businesses.some((b) => b.slug === slug);
    return bookmarks.videos.some((v) => v.slug === slug);
  }, [bookmarks, type, slug]);

  const setBookmarkedOnServer = async (value: boolean) => {
    if (!slug) return;
    const newBookmarks =
      type === 'business'
        ? await PublicContentService.setBusinessBookmarked(slug, value)
        : await PublicContentService.setVideoBookmarked(slug, value);
    setBookmarks(newBookmarks);
    if (value) popMessageSuccess(`This ${type} has been added to your profile.`);
    else popMessageSuccess(`This ${type} has been removed from your profile.`);
  };

  return (
    <LSTVButton
      eventAction={optimisticBookmarked ?? bookmarked ? 'Unbookmark' : 'Bookmark'}
      onClick={(event) => {
        // Prevent navigation via enclosing link.
        event.preventDefault();
        // Prevent click-link event from being generated.
        event.stopPropagation();
        if (loggedIn) {
          const newBookmarked = !(optimisticBookmarked ?? bookmarked);
          setOptimisticBookmarked(newBookmarked);
          setBookmarkedOnServer(newBookmarked).finally(() => {
            setOptimisticBookmarked(undefined);
          });
        } else {
          openLoginModal();
        }
      }}
      {...{ className }}
    >
      <FontAwesomeIcon icon={optimisticBookmarked ?? bookmarked ? faBookmarkSolid : faBookmark} size="lg" />
    </LSTVButton>
  );
};
