import {
  EmailShareButton,
  FacebookMessengerShareButton,
  FacebookShareButton,
  PinterestShareButton,
  TwitterShareButton,
  WhatsappShareButton,
} from 'react-share';
import styled from 'styled-components';
import {
  FacebookIcon,
  FacebookMessengerIcon,
  MailIcon,
  PinterestIcon,
  TwitterIcon,
  WhatsAppIcon,
} from '~/components/InlineSVG';
import { UserDevice } from '~/globals';
import { useTrackEvent } from '~/globals/trackEvent';

const ButtonContainer = styled.div`
  display: flex;
  justify-content: space-around;
  padding: 20px 0px;
  max-width: 400px;
  margin: auto;

  @media ${UserDevice.isWithinTablet} {
    flex-wrap: wrap;

    button {
      flex-basis: 25%;
      margin-bottom: 20px;
    }
  }
`;

const ButtonCircle = styled.div`
  border: 2px solid black;
  margin: auto;
  border-radius: 100%;
  width: 35px;
  height: 35px;
  display: flex;
  align-content: center;
  justify-content: center;
  align-items: center;
`;

type Props = {
  urlToShare: string;
  thumbnailUrl?: string;
};

const SocialMediaButtonsBar = ({ urlToShare, thumbnailUrl }: Props) => {
  const trackEvent = useTrackEvent();

  const trackSocialButtonClick = (social_channel) => () => {
    trackEvent('share', { social_channel });
  };

  return (
    <ButtonContainer>
      {thumbnailUrl !== undefined && (
        <PinterestShareButton url={urlToShare} media={thumbnailUrl} onClick={trackSocialButtonClick('pinterest')}>
          <ButtonCircle>
            <PinterestIcon width={40} strokeColor="none" />
          </ButtonCircle>
        </PinterestShareButton>
      )}
      <FacebookShareButton url={urlToShare} onClick={trackSocialButtonClick('facebook')}>
        <ButtonCircle>
          <FacebookIcon width={40} />
        </ButtonCircle>
      </FacebookShareButton>
      <FacebookMessengerShareButton
        url={urlToShare}
        appId={undefined}
        onClick={trackSocialButtonClick('facebook_messenger')}
      >
        <ButtonCircle>
          <FacebookMessengerIcon width={40} />
        </ButtonCircle>
      </FacebookMessengerShareButton>
      <TwitterShareButton url={urlToShare} onClick={trackSocialButtonClick('twitter')}>
        <ButtonCircle>
          <TwitterIcon width={40} />
        </ButtonCircle>
      </TwitterShareButton>
      <WhatsappShareButton url={urlToShare} onClick={trackSocialButtonClick('whatsapp')}>
        <ButtonCircle>
          <WhatsAppIcon width={40} />
        </ButtonCircle>
      </WhatsappShareButton>
      {/* Using onClickCapture instead of onClick because onClick breaks this widget. */}
      <EmailShareButton url={urlToShare} onClickCapture={trackSocialButtonClick('email')}>
        <ButtonCircle>
          <MailIcon width={40} />
        </ButtonCircle>
      </EmailShareButton>
    </ButtonContainer>
  );
};

export default SocialMediaButtonsBar;
