/* eslint-disable no-unused-vars */
import React, { useState } from 'react';
import { isMobileOnly } from 'react-device-detect';
import Sticky from 'react-sticky-el';
import styled from 'styled-components';
import HeaderBar from './HeaderBar';
import GoogleAd from '~/components/GoogleAd';
import * as LSTVGlobals from '~/globals';
import { isBackdropFilterSupported, VerticalSpacer } from '~/utils/LSTVUtils';
import { EventParams } from '~/globals/trackEvent';
import TopBar from './TopBar';
import MessageBar from './MessageBar';

const Container = styled.div`
  position: relative;
  z-index: 30;
`;

const Header = ({ withBanner }: { withBanner?: boolean }) => {
  const [fixed, setFixed] = useState(false);
  const backdropSupported = isBackdropFilterSupported();
  const stickyStyle = backdropSupported ? LSTVGlobals.TOP_STICKY_NAVBAR : LSTVGlobals.TOP_STICKY_NAVBAR_NO_BLUR;

  return (
    <EventParams section="header">
      <MessageBar />
      <TopBar />
      <Container>
        {fixed && withBanner && (
          <>
            <GoogleAd
              width={isMobileOnly ? 320 : 728}
              height={isMobileOnly ? 50 : 90}
              adUnitPath={isMobileOnly ? '/164808479/Mobile320x50' : '/164808479/lstv2-leaderboards'}
            />
            <VerticalSpacer space={5} />
          </>
        )}
        <Sticky onFixedToggle={() => setFixed(!fixed)} style={stickyStyle}>
          <HeaderBar />
        </Sticky>
      </Container>
    </EventParams>
  );
};

export default Header;
