import IUser from '@lstv/core/types/IUser';
import { useRouter } from 'next/router';
import { useMemo } from 'react';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown, faArrowToTop, faSignOut, faUser, IconDefinition } from '@fortawesome/pro-regular-svg-icons';
import { useAuthService } from '~/api/hooks/useAuthService';
import Dropdown from '~/components/Dropdown';
import { USER_TYPE_CONSUMER, USER_TYPE_VENDOR_TEAM_MEMBER, USER_TYPE_VENDOR_TEAM_MEMBER_ONBOARDING } from '~/globals';
import * as S from './HeaderBar.styles';
import { useNavBar } from '~/hooks/useNavBar';

type MenuItem = {
  type: string;
  to: string;
  title?: string;
  name?: string;
  subTitle?: string;
  imageSrc?: string;
  icon?: IconDefinition;
  hideOnMobile?: boolean;
};

const generateMenuItems = (user) => {
  const defaultItems: MenuItem[] = [
    {
      type: 'PROFILE',
      title: `${user.firstName} ${user.lastName}`,
      subTitle: 'See your dashboard',
      to: user.userType === USER_TYPE_VENDOR_TEAM_MEMBER ? `/dashboard/info` : `/profile`,
      imageSrc: user.profileThumbnail,
    },
  ];

  if (user.userType === USER_TYPE_VENDOR_TEAM_MEMBER_ONBOARDING) {
    defaultItems.push({ type: 'STANDARD', name: 'Set Up Profile', to: '/edit-profile-pro' });
  } else if (user.userType === USER_TYPE_CONSUMER) {
    defaultItems.push({ type: 'STANDARD', name: 'Bookmarks', to: '/profile' });
  } else if (user.userType === USER_TYPE_VENDOR_TEAM_MEMBER) {
    let roles = [];

    if (user.businessRoles) {
      roles = user.businessRoles.map((role) => role.name);
    }

    defaultItems.push({
      type: 'PROFILE',
      title: user.businessName,
      subTitle: 'See your business page',
      to: `/business/${user.businessSlug}`,
      imageSrc: user.businessThumbnail,
    });

    if (roles.includes('Videographer')) {
      defaultItems.push({
        type: 'STANDARD',
        name: 'Upload Videos',
        to: '/dashboard/upload-video',
        icon: faArrowToTop,
        hideOnMobile: true,
      });
    }

    defaultItems.push({ type: 'STANDARD', name: 'Bookmarks', to: '/profile' });
  }

  return defaultItems;
};

export const UserMenuItems: React.FC<{ onLinkClick?: () => void }> = () => {
  const { user, signOut } = useAuthService();
  const router = useRouter();
  const { setModal } = useNavBar();

  const menuItems = useMemo(() => generateMenuItems(user), [user]);

  const routeTo = (to: string) => {
    setModal('none');
    router.push(to);
  };

  const renderProfileMenuItem = ({ title, subTitle, to, imageSrc }: MenuItem, index: number) => (
    <S.ProfileMenuItem onClick={() => routeTo(to)} key={index}>
      <FontAwesomeIcon icon={faUser} size="lg" />
      <div>
        <p>{title}</p>
        <small>{subTitle}</small>
      </div>
    </S.ProfileMenuItem>
  );

  return (
    <>
      {menuItems.map((link, index) =>
        link.type === 'PROFILE' ? (
          renderProfileMenuItem(link, index)
        ) : (
          <S.UserMenuItem onClick={() => routeTo(link.to)} key={index} $hideOnMobile={link.hideOnMobile}>
            {link.icon && <FontAwesomeIcon icon={link.icon} style={{ marginRight: 10 }} />}
            {link.name}
          </S.UserMenuItem>
        )
      )}
      <S.NonLinkMenuItem onClick={signOut}>
        <FontAwesomeIcon icon={faSignOut} style={{ marginRight: 10 }} />
        Sign Out
      </S.NonLinkMenuItem>
    </>
  );
};

export const UserMenu = () => {
  return (
    <S.DropdownMenu>
      <UserMenuItems />
    </S.DropdownMenu>
  );
};

const Button = styled.div`
  background-color: transparent;
  cursor: pointer;
  display: flex;
  align-items: center;
`;

const UserMenuDropdown = () => {
  const toggle = (
    <Button>
      <FontAwesomeIcon icon={faUser} style={{ marginLeft: 8, width: 21, height: 21 }} />
      <FontAwesomeIcon icon={faChevronDown} style={{ marginLeft: 8, width: 14 }} />
    </Button>
  );

  return <Dropdown id="user_menu_dropdown" alignEnd toggle={toggle} menu={<UserMenu />} />;
};

export default UserMenuDropdown;
