import TimeAgo from 'javascript-time-ago';
import en from 'javascript-time-ago/locale/en';
import { NextWebVitalsMetric } from 'next/app';
import { useRouter } from 'next/router';
import dynamic from 'next/dynamic';
import Script from 'next/script';
import { parseCookies } from 'nookies';
import React, { useEffect, useState } from 'react';
import { ThemeProvider as StyledComponentsThemeProvider } from 'styled-components';
import IUser from '@lstv/core/types/IUser';
import { AuthContext, userCookieName } from '~/api/hooks/useAuthService';
import DefaultLayout from '~/components/layouts/DefaultLayout';
import { GlobalTypographyStyles } from '~/components/typography';
import { identifyToKlaviyo } from '~/globals/trackEvent';
import Analytics from '~/lib/Analytics';
import { MediaContextProvider as FresnelMediaProvider } from '~/styles/media';
import theme from '~/styles/styledComponentsTheme';
import Router from 'next/router';
import NProgress from 'nprogress';
import { IBookmarks } from '@lstv/core/types';
import authService from '~/api/services/authService';
// global style imports have to be in _app. Or use CSS modules.
// https://nextjs.org/docs/basic-features/built-in-css-support#adding-component-level-css
import '~/styles/globals.scss';
import 'swiper/swiper.scss';
import '~/styles/nprogress.scss';
import smoothscroll from 'smoothscroll-polyfill';
// Prevent FUOC https://github.com/FortAwesome/react-fontawesome/issues/234#issuecomment-815649634
import '@fortawesome/fontawesome-svg-core/styles.css';

const MarketingModal = dynamic(() => import('~/components/modals/MarketingModal'), { ssr: false });

// required to use TimeAgo w/in the app. Does time since strings "15s", "yesterday", etc
TimeAgo.addLocale(en);

// https://nextjs.org/docs/advanced-features/measuring-performance
export function reportWebVitals(metric: NextWebVitalsMetric) {
  // console.log(metric)
  // can then send metrics to analytics or a custom endpoint if we want
}

NProgress.configure({ showSpinner: false });
Router.events.on('routeChangeStart', () => NProgress.start());
Router.events.on('routeChangeComplete', () => NProgress.done());
Router.events.on('routeChangeError', () => NProgress.done());

const Scripts: React.FC = () => (
  <>
    <Script
      src="https://maps.googleapis.com/maps/api/js?key=AIzaSyCmkzi07c9x40XQFleo1GU_2VBLWI1vYH8&libraries=places,drawing,geometry&language=en"
      strategy="beforeInteractive"
    />
    <Script src="//static.klaviyo.com/onsite/js/klaviyo.js?company_id=VsYF9a" strategy="beforeInteractive" />
  </>
);

function LSTV({ Component, pageProps, err }) {
  const router = useRouter();
  const [user, setUser] = useState<IUser>();
  const [bookmarks, setBookmarks] = useState<IBookmarks>();
  const [loadingAuth, setLoadingAuth] = useState(true);

  useEffect(() => {
    if (user) authService.getBookmarks().then((resp) => setBookmarks(resp));
    else setBookmarks(null);
  }, [user]);

  const setAndIdentifyUser = React.useCallback((user: IUser) => {
    identifyToKlaviyo(user);
    setUser(user);
  }, []);

  useEffect(() => {
    const cookies = parseCookies();
    if (cookies[userCookieName]) {
      // TODO: it'd be better to query for user details in case
      // they've changed on the server rather than using what was
      // saved in the cookie up on last signin
      setAndIdentifyUser(JSON.parse(cookies[userCookieName]));
    }
    setLoadingAuth(false);
  }, [setAndIdentifyUser]);

  useEffect(() => {
    smoothscroll.polyfill();
  }, []);

  if (router.pathname.startsWith('/embed/') || router.pathname.startsWith('/embed-badge')) {
    return (
      <AuthContext.Provider value={{ user, bookmarks, setUser: setAndIdentifyUser, setBookmarks, loadingAuth }}>
        <Scripts />
        <FresnelMediaProvider>
          <Component {...pageProps} err={err} />
        </FresnelMediaProvider>
      </AuthContext.Provider>
    );
  }

  return (
    <AuthContext.Provider value={{ user, bookmarks, setUser: setAndIdentifyUser, setBookmarks, loadingAuth }}>
      <Scripts />
      <StyledComponentsThemeProvider theme={theme}>
        <FresnelMediaProvider>
          <GlobalTypographyStyles />
          <Analytics />
          <DefaultLayout>
            <Component {...pageProps} err={err} />
          </DefaultLayout>
          <MarketingModal />
        </FresnelMediaProvider>
      </StyledComponentsThemeProvider>
    </AuthContext.Provider>
  );
}

export default LSTV;
