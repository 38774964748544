import styled from 'styled-components';
import * as LSTVGlobals from '~/globals';

export const AdBannerStyle = styled.div<{ $height: number; $width: number }>`
  display: block;
  text-align: center;
  font-size: 1.5rem;
  margin: 5px auto 0 auto;
  height: ${(props) => props.$height}px;
  width: ${(props) => props.$width}px;
  max-width: 100vw;
`;

export const AdBannerStyleDev = styled(AdBannerStyle)`
  border: 1px dashed ${LSTVGlobals.DISABLED_FRAME_TEXT};

  p {
    color: ${LSTVGlobals.DISABLED_FRAME_TEXT};
  }
`;

export const AdContainer = styled.div`
  @media ${LSTVGlobals.UserDevice.laptop} {
    margin-bottom: 10px;
  }
`;
