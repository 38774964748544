import React from 'react';
import { useTrackEvent } from '~/globals/trackEvent';

export interface LSTVButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  eventAction?: string;
}

/**
 * A button that tracks events.
 *
 * Not a fan of this name. Maybe TrackableButton ?
 */
// eslint-disable-next-line react/display-name
const LSTVButton = React.forwardRef<HTMLButtonElement, LSTVButtonProps>(
  ({ onClick, eventAction, 'aria-label': ariaLabel, ...rest }, ref) => {
    const trackEvent = useTrackEvent();
    return (
      <button
        onClick={(event) => {
          const finalEventAction = eventAction ?? ariaLabel;
          if (finalEventAction) {
            trackEvent('click_button', { button_action: finalEventAction });
          }
          return onClick?.(event);
        }}
        aria-label={ariaLabel}
        {...{ ref }}
        {...rest}
      />
    );
  }
);

export default LSTVButton;
