import React, { ReactNode } from 'react';
import styled, { css } from 'styled-components';
import theme from '~/styles/styledComponentsTheme';
import { maxWidth } from '~/styles/media';

const applyStyles = ({
    marginTop,
    marginBottom,
    marginLeft,
    marginRight,
    margin,
    padding,
    color,
    center,
    fontWeight,
    customStyles,
}: Props) => {
    return css`
        font-family: 'Calibre';
        font-style: normal;
        width: 100%;

        ${marginTop && `margin-top: ${marginTop};`}
        ${marginBottom && `margin-bottom: ${marginBottom};`}
    ${marginLeft && `margin-left: ${marginLeft};`}
    ${marginRight && `margin-right: ${marginRight};`}

    ${margin && `margin: ${margin};`}
    ${padding && `padding: ${padding};`}

    ${center && `text-align: center;`}
    ${fontWeight && `font-weight: ${fontWeight};`}
    ${color && `color: ${color};`}
    /* TODO: remove it once we will handle all the fontWeights in the Typography component */
    font-weight: normal;
        ${customStyles}
    `;
};

const S = {
    h1: styled.h1`
        font-size: 40px;
        line-height: 48px;
        text-align: left;
        // TODO do we need this?
        padding-bottom: 16px;
        @media ${maxWidth('sm')} {
            font-size: 2.5rem;
        }

        ${applyStyles}
    `,
    h2: styled.h2`
        font-size: 30px;
        line-height: 36px;
        text-align: left;

        ${(props: Props) =>
            props.type === 'section-title' &&
            css`
                margin: 32px 0 0 0;
                max-width: 650px;
            `}

        ${(props: Props) =>
            props.type === 'form-title' &&
            css`
                z-index: 2;
                width: fit-content;
            `}

    ${(props: Props) =>
            props.type === 'static-title' &&
            css`
                margin: 0 0 24px 0;
                color: ${theme.white};

                @media ${maxWidth('md')} {
                    text-align: center;
                }
            `}

    // TODO it's coming from HeroTitle, maybe it should be a rule for all h2 or even all h tags?
    @media ${maxWidth('md')} {
            text-align: center;
        }

        ${(props: Props) => applyStyles(props)}
    `,
    h3: styled.h3`
        font-size: 25px;
        line-height: 30px;
        text-align: left;
        ${applyStyles}
    `,
    h4: styled.h4`
        font-size: 23px;
        line-height: 27px;
        text-align: left;
        ${applyStyles}
    `,
    h5: styled.h5`
        font-size: 20px;
        text-align: left;
        line-height: 24px;
        ${applyStyles}
    `,
    h6: styled.h6`
        font-size: 18px;
        line-height: 21px;
        text-align: left;
        ${applyStyles}
    `,
};

type TypographyVariant = 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6';
type Type = 'section-title' | 'static-title' | 'form-title';

type Props = {
    variant: TypographyVariant;
    type?: Type;
    children: ReactNode;
    marginTop?: string;
    marginBottom?: string;
    marginLeft?: string;
    marginRight?: string;
    margin?: string;
    padding?: string;
    color?: string;
    customStyles?: string;
    center?: Boolean;
    fontWeight?: string;
    id?: string;
    onClick?: Function;
};

const Typography = ({
    variant = 'h1',
    type,
    marginTop,
    marginBottom,
    marginLeft,
    marginRight,
    margin,
    padding,
    color,
    customStyles,
    center,
    fontWeight,
    id,
    children,
    onClick,
}: Props) => {
    const H: React.ComponentType<any> = S[variant];

    return (
        <H
            type={type}
            marginTop={marginTop}
            marginBottom={marginBottom}
            marginLeft={marginLeft}
            marginRight={marginRight}
            margin={margin}
            padding={padding}
            color={color}
            customStyles={customStyles}
            center={center}
            fontWeight={fontWeight}
            id={id}
            onClick={onClick}
        >
            {children}
        </H>
    );
};

export default Typography;
