import styled from 'styled-components';
import theme from '~/styles/styledComponentsTheme';
import { useDirectories } from '~/hooks/useDirectories';
import { useSearch } from '~/hooks/useSearch';

interface CardContainerProps {
  background: string;
  color: string;
}
const CardContainer = styled.div<CardContainerProps>`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  width: 100%;
  height: 120px;
  padding: 16px;
  background: ${(props) =>
    props.background
      ? 'linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.5)), url(' + props.background + ') center/cover'
      : props.color};
  border-radius: 10px;
  cursor: pointer;
`;

const Title = styled.p`
  font-family: Calibre;
  color: white;
  font-size: 18px;
  line-height: 21px;
  font-weight: 600;

  @media ${theme.breakpoints.isTablet} {
    font-size: 25px;
  }
`;

const Container = styled.div`
  display: grid;
  justify-content: flex-start;
  grid-gap: 20px;
  padding: 24px;

  @media ${theme.breakpoints.laptop} {
    grid-template-columns: repeat(3, 1fr);
  }

  @media ${theme.breakpoints.laptopL} {
    grid-template-columns: repeat(4, 1fr);
  }

  @media ${theme.breakpoints.isMobileOrTablet} {
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 16px;
  }
`;

const SuggestedCard = ({ card, onClick }) => {
  const { name, weight, bg_color, bg_image_url } = card;
  // console.log(bg_image_url, card);

  return (
    <CardContainer background={bg_image_url} color={bg_color || theme.highlight_7_0} onClick={onClick}>
      <Title>{name}</Title>
    </CardContainer>
  );
};

const SuggestedCards = () => {
  const { directories } = useDirectories();
  const { handleSelectFreeText } = useSearch();

  return (
    !!directories?.length && (
      <Container>
        {directories?.map((dir) => (
          <SuggestedCard key={dir.name} card={dir} onClick={() => handleSelectFreeText(dir)} />
        ))}
      </Container>
    )
  );
};

export default SuggestedCards;
