import styled from 'styled-components';
import theme from '~/styles/styledComponentsTheme';
import Link from '~/components/Link';
import LSTVLink from '~/components/LSTVLink';

export const Loading = styled.div`
  height: 200px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const List = styled.ul`
  @media ${theme.breakpoints.laptop} {
    max-height: 300px;
  }
`;

export const Item = styled.li`
  border-bottom: 0.5px solid rgba(186, 186, 186, 0.25);
  padding: 13px 0;
  position: relative;
  color: ${theme.black};
  display: flex;
  justify-content: space-between;
  align-items: center;

  :hover {
    background: ${(props) => props.theme.lightGrey};
  }
`;

export const Icon = styled.div`
  width: 28px;
  height: 26px;
  margin-left: 24px;
  // so that items w icons come to the ~same height as items without
  margin-top: -3px;
  margin-bottom: -4px;
`;

export const ItemLink = styled(LSTVLink)`
  height: 100%;
  width: 100%;
  text-decoration: none;

  :hover {
    color: inherit;
  }
`;

export const ItemText = styled.p<{ $isDirectory?: boolean }>`
  flex: 1;
  font-size: 1.125rem;
  font-family: Calibre;
  font-weight: 500;
  margin-left: ${(props) => (props.$isDirectory ? '10px' : '30px')};
`;

export const ItemLocation = styled.p`
  font-weight: 600;
  font-family: Calibre;
  padding: 0 16px 0 0;
  max-width: 40%;
  text-align: right;
`;
