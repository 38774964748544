import IUser from '@lstv/core/types/IUser';
import { IBookmarks } from '@lstv/core/types';
import { useRouter } from 'next/router';
import { destroyCookie, setCookie } from 'nookies';
import { createContext, useContext, useEffect, useState } from 'react';
import authService from '~/api/services/authService';
import { USER_TYPE_CONSUMER, USER_TYPE_VENDOR_TEAM_MEMBER, USER_TYPE_VENDOR_TEAM_MEMBER_ONBOARDING } from '~/globals';
import Navigator from '~/lib/Navigator';
import { getQueryParam } from '~/utils/url';
import { useServerErrors } from './useServerErrors';

export const adaptResponseToUser = (response = {}) => ({
  uid: response['uid'],
  firstName: response['first_name'],
  lastName: response['last_name'],
  profileThumbnail: response['profile_thumbnail_url'],
  email: response['email'],
  userType: response['user_type'],
  businessName: response['business_name'],
  businessRoles: response['business_roles'],
  teamMemberPermissions: response['team_member_permissions'],
  businessThumbnail: response['business_thumbnail_url'],
  businessSlug: response['business_slug'],
  businessId: response['business_id'],
});

// create and export an auth context for _app
type Context = {
  user: IUser;
  setUser: (user: IUser) => void;
  bookmarks: IBookmarks;
  setBookmarks: (bookmarks: IBookmarks) => void;
  loadingAuth: boolean;
};
export const AuthContext = createContext<Context>((null as unknown) as Context);
export const userCookieName = 'lstv.client.user';

export const useAuthService = () => {
  const router = useRouter();
  const { user, setUser, bookmarks, setBookmarks, loadingAuth } = useContext(AuthContext);
  const { errorMessages, analyzeServerErrors } = useServerErrors();

  const [result, setResult] = useState();
  const [redirectUrl, setRedirectUrl] = useState<string>();

  useEffect(() => {
    const from = getQueryParam(router.query, 'from');
    setRedirectUrl(from);
  }, [router.asPath]);

  const setUserAndCookie = (user) => {
    setUser(user);
    // set max-age? find out duration of server token cookie
    setCookie(null, userCookieName, JSON.stringify(user));
  };

  const onLoginSuccess = (response) => {
    const user = adaptResponseToUser(response);
    setUserAndCookie(user);
  };

  const goToEditProfile = (userType) =>
    router.push({
      pathname:
        userType === USER_TYPE_VENDOR_TEAM_MEMBER || userType === USER_TYPE_VENDOR_TEAM_MEMBER_ONBOARDING
          ? '/edit-profile-pro'
          : '/profile',
      search: location.search,
    });

  const goToSignUp = () =>
    router.push({
      pathname: Navigator.signUp,
      search: location.search,
    });

  const goToSignIn = () =>
    router.push({
      pathname: Navigator.signIn,
      search: location.search,
    });

  const goToRedirectOrHome = () => router.push(redirectUrl || Navigator.home);

  const goToLogin = () => {
    // Redirect user if they came from any route not in NO_REDIRECT_ROUTES
    const NO_REDIRECT_ROUTES = [Navigator.home, Navigator.signIn, Navigator.signUp, '/profile'];
    const redirectTo = NO_REDIRECT_ROUTES.includes(location.pathname) ? '' : `?from=${location.pathname}`;
    router.push(`/sign-in${redirectTo}`);
  };

  const signInWIthOauth = (payload, userType, redirectOnSuccess = true) => {
    return authService.loginWithOAuth(payload, userType).then(
      ({ data }) => {
        console.log('====================================');
        console.log('signInWithOauth', data);
        console.log('====================================');

        setResult(data);
        onLoginSuccess(data);

        if (redirectOnSuccess) {
          if (data.isNewUser || data['post-signup-interview-required']) {
            goToEditProfile(data.user_type);
          } else {
            goToRedirectOrHome();
          }
        }
      },
      (error) => {
        analyzeServerErrors(error);
      }
    );
  };

  return {
    loggedIn: !!user?.uid,
    ready: !loadingAuth,
    user,
    setUser: setUserAndCookie,
    bookmarks,
    setBookmarks,
    // TODO: What do we need this for?
    result,
    errorMessages,
    goToLogin,
    goToSignIn,
    goToSignUp,
    goToRedirectOrHome,
    updateUser(data) {
      return authService.updateUser(data).then(
        (data) => setResult(data),
        (error) => analyzeServerErrors(error)
      );
    },
    updateUserProfile(data) {
      return authService.updateUserProfile(data).then(
        (data) => setResult(data),
        (error) => analyzeServerErrors(error)
      );
    },
    updateBusinessProfile(data) {
      const shouldConvertBusinessTypes =
        Array.isArray(data.business_roles) && data.business_roles[0] && data.business_roles[0].value;
      if (shouldConvertBusinessTypes) {
        data.business_roles = data.business_roles.map(({ value }) => value).join(', ');
      }

      return authService.updateBusinessProfile(data).then(
        (result) => {
          const newUser = {
            ...user,
            businessRoles: result.business_roles,
            businessName: result.business_name,
            businessSlug: result.business_slug,
            businessId: result.business_id,
            location: result.location,
            teamMemberPermissions: result.team_member_permissions,
            businessThumbnail: result.business_thumbnail_url,
            userType: USER_TYPE_VENDOR_TEAM_MEMBER,
          };

          setUserAndCookie(newUser);
          router.push(Navigator.dashboard.info);
        },
        (error) => {
          return Promise.reject(analyzeServerErrors(error));
        }
      );
    },

    signInWithOAuthForGuest(payload, redirectOnSuccess = true) {
      return signInWIthOauth(payload, USER_TYPE_CONSUMER, redirectOnSuccess);
    },

    signInWithOAuthForBusinessTeamMember(payload, redirectOnSuccess = true) {
      return signInWIthOauth(payload, USER_TYPE_VENDOR_TEAM_MEMBER_ONBOARDING, redirectOnSuccess);
    },

    signUpAndClaimBusiness(
      businessSlug: string,
      claimData: {
        code: string;
        email: string;
        first_name: string;
        last_name: string;
        password: string;
      }
    ): Promise<any /* TFIXME */> {
      return authService.signUpAndClaimBusiness(businessSlug, claimData).then(
        (data) => {
          setResult(data);
          onLoginSuccess(data);
          // really should have all these routes somewhere as constants
          router.push('/dashboard/info');
        },
        (error) => analyzeServerErrors(error)
      );
    },

    verifyAccountClaim(code: string): Promise<any /* TSFIXME */> {
      return authService.verifyAccountClaim(code);
    },

    /*
          _                                  __                    __            __
      ___(_) __ _ _ __        _   _ _ __    / /   _ ___  ___ _ __ / / ____      _\ \
     / __| |/ _` | '_ \ _____| | | | '_ \  | | | | / __|/ _ \ '__/ / '_ \ \ /\ / /| |
     \__ \ | (_| | | | |_____| |_| | |_) | | | |_| \__ \  __/ | / /| |_) \ V  V / | |
     |___/_|\__, |_| |_|      \__,_| .__/  | |\__,_|___/\___|_|/_/ | .__/ \_/\_/  | |
            |___/                  |_|      \_\                    |_|           /_/

     */

    signUpWithEmailAndPassword(data, redirectOnSuccess = true) {
      return authService.signUpWithEmailAndPassword(data).then(
        (data) => {
          setResult(data);
          onLoginSuccess(data);
          if (redirectOnSuccess) {
            goToEditProfile(data.user_type);
          }
        },
        (error) => {
          return analyzeServerErrors(error);
        }
      );
    },

    handleOAuthFailure(error) {
      analyzeServerErrors(error.details);
    },

    /*
          _                   _          __                    __           __
      ___(_) __ _ _ __       (_)_ __    / /   _ ___  ___ _ __ / /____      _\ \
     / __| |/ _` | '_ \ _____| | '_ \  | | | | / __|/ _ \ '__/ / _ \ \ /\ / /| |
     \__ \ | (_| | | | |_____| | | | | | | |_| \__ \  __/ | / / (_) \ V  V / | |
     |___/_|\__, |_| |_|     |_|_| |_| | |\__,_|___/\___|_|/_/ \___/ \_/\_/  | |
            |___/                       \_\                                 /_/

     */

    signInWithEmailAndPassword(data) {
      return authService.signInWithEmailAndPassword(data).then(
        (result) => {
          setResult(result);
          onLoginSuccess(result);

          if (result['post-signup-interview-required']) {
            goToEditProfile(result.user_type);
          } else {
            goToRedirectOrHome();
          }
        },
        (error) => {
          return analyzeServerErrors(error);
        }
      );
    },

    signOut() {
      return authService
        .signOut()
        .then(
          (result) => {},
          (error) => {
            console.error(error);
          }
        )
        .then(() => {
          console.log("---signing off")
          setUser(null);
          destroyCookie(null, userCookieName);
          goToRedirectOrHome();
        });
    },

    resetPasswordStart(email) {
      return authService.resetPasswordStart(email).then(
        (result) => {
          setResult(result);
        },
        (error) => {
          analyzeServerErrors(error);
        }
      );
    },
    resetPasswordFinish({ password, code }) {
      return authService.changePassword({ password, code }).then(
        (res) => {
          setResult(res.data.result);
          return res;
        },
        (error) => {
          return analyzeServerErrors(error);
        }
      );
    },
  };
};
