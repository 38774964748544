import { Bling as GPT } from 'react-gpt';
import * as LSTVGlobals from '~/globals';
import * as S from './index.styles';

type Props = {
  width: number;
  height: number;
  adUnitPath: string;
};

const GoogleAd = ({ width, height, adUnitPath }: Props) => (
  <S.AdContainer className="ad">
    {LSTVGlobals.IS_DEV() ? (
      <div>
        <S.AdBannerStyleDev $width={width} $height={height}>
          <LSTVGlobals.VCenteredParagraph>
            [{width}x{height} Google Ad]
          </LSTVGlobals.VCenteredParagraph>
        </S.AdBannerStyleDev>
      </div>
    ) : (
      <div>
        <S.AdBannerStyle $width={width} $height={height}>
          <GPT adUnitPath={adUnitPath} slotSize={[width, height]} />
        </S.AdBannerStyle>
      </div>
    )}
  </S.AdContainer>
);

export default GoogleAd;
