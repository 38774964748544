import styled from 'styled-components';
import { useAuthService } from '~/api/hooks/useAuthService';
import BaseCTAButton from '~/components/buttons/BaseCtaButton';
import { EventParams } from '~/globals/trackEvent';
import { useModals } from '~/hooks/useModals';
import Modal from './Modal';

const Container = styled.div`
  padding: 16px 8px;
`;

const Buttons = styled.div`
  padding: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const NewAccount = styled.button`
  background-color: unset;
  text-decoration: underline;
  margin-top: 20px;
  cursor: pointer;
`;

const LoginModal = () => {
  const { isLoginModalOpen, closeLoginModal } = useModals();
  const { goToLogin, goToSignUp } = useAuthService();

  const handleLoginClicked = () => {
    closeLoginModal();
    goToLogin();
  };
  const handleSignUpClicked = () => {
    closeLoginModal();
    goToSignUp();
  };

  if (!isLoginModalOpen) return null;

  return (
    <EventParams modal="login">
      <Modal
        open
        data-scroll-lock-scrollable
        height="fit-content"
        width="75vw"
        onClose={closeLoginModal}
        title="Log in to continue"
        customStyles={{ content: { overflow: 'unset', maxWidth: '500px', margin: 'auto' }, container: {} }}
      >
        <Container>
          <Buttons>
            <BaseCTAButton title={'Login'} size={'medium'} onClick={() => handleLoginClicked()} />
            <NewAccount onClick={() => handleSignUpClicked()}>Create new acount</NewAccount>
          </Buttons>
        </Container>
      </Modal>
    </EventParams>
  );
};

export default LoginModal;
