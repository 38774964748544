import Image from 'next/image';
import { ReactNode } from 'react';
import styled from 'styled-components';
import LSTVButton, { LSTVButtonProps } from '~/components/LSTVButton';
import LSTVLink from '~/components/LSTVLink';
import purpleLoader from '~/images/button-loading-purple.gif';

const StyledLink = styled(LSTVLink)<{ $width?: string; $dark?: boolean }>`
  width: ${(props) => props.$width ?? '254px'};
  text-align: center;
  text-decoration: none;
  padding-top: 14px;
  padding-bottom: 14px;
  font-family: Calibre;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 21px;
  border: 2px solid;
  box-sizing: border-box;
  border-radius: 90px;
  transition: all 0.3s ease;
  cursor: pointer;

  &:hover {
    color: ${(props) => (props.$dark ? props.theme.black : props.theme.white)};
    border-color: ${(props) => (props.$dark ? props.theme.white : props.theme.highlight_7_0)};
    background-color: ${(props) => (props.$dark ? props.theme.white : props.theme.highlight_7_0)};
  }

  &.filled {
    color: ${(props) => (props.$dark ? props.theme.black : props.theme.white)};
    border-color: ${(props) => (props.$dark ? props.theme.white : props.theme.highlight_7_0)};
    background-color: ${(props) => (props.$dark ? props.theme.white : props.theme.highlight_7_0)};
  }
`;

export type OutlinedCTALinkProps = {
  dark?: boolean;
  width?: string;
  to: string;
  className?: string;
  children: ReactNode;
};

export const OutlinedCTALink = ({ dark, width, ...props }: OutlinedCTALinkProps) => (
  <StyledLink $dark={dark} $width={width} {...props} />
);

const StyledButton = styled(LSTVButton)<{
  $width?: string;
  $height?: string;
  $short?: boolean;
  $dark?: boolean;
  $dsm?: boolean;
}>`
  width: ${(props) => props.$width ?? (props.$dsm ? '208px' : '254px')};
  height: ${(props) => (props.$height ?? props.$short ? '35px' : '51px')};
  text-decoration: none;
  font-family: Calibre;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 21px;
  background-color: ${(props) => (props.$dsm ? 'transparent' : 'inherit')};
  border: 2px solid;
  box-sizing: border-box;
  border-radius: 90px;
  transition: all 0.3s ease;

  &:enabled {
    cursor: pointer;
  }

  &:hover:enabled {
    color: ${(props) => (props.$dsm ? props.theme.white : props.$dark ? props.theme.white : props.theme.highlight_7_0)};
    border-color: ${(props) => (props.$dark ? props.theme.white : props.theme.highlight_7_0)};
    background-color: ${(props) =>
      props.$dsm && (props.$dark ? props.theme.translucentWhite : props.theme.highlight_7_0)};
  }

  &.filled {
    color: ${(props) => (props.$dark ? props.theme.black : props.theme.white)};
    border-color: ${(props) => (props.$dark ? props.theme.white : props.theme.highlight_7_0)};
    background-color: ${(props) => (props.$dark ? props.theme.white : props.theme.highlight_7_0)};
  }

  &:disabled {
    opacity: 20%;
  }
`;

const Loading = styled.div`
  flex: 1;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
`;

type Props = {
  short?: boolean;
  dark?: boolean;
  width?: string;
  height?: string;
  loading?: boolean;
  dsm?: boolean;
} & Pick<LSTVButtonProps, 'children' | 'className' | 'style' | 'onClick' | 'type' | 'disabled' | 'eventAction'>;

export const OutlinedCTAButton = ({
  children,
  loading,
  short,
  dark,
  width,
  height,
  dsm,
  className,
  style,
  onClick,
  type,
  disabled,
  eventAction,
}: Props) => (
  <StyledButton
    $short={short}
    $dark={dark}
    $width={width}
    $height={height}
    $dsm={dsm}
    {...{ className, style, onClick, type, disabled, eventAction }}
  >
    {loading ? (
      <Loading>
        <Image src={purpleLoader} alt="Loading" width={47} height={47} />
      </Loading>
    ) : (
      children
    )}
  </StyledButton>
);
